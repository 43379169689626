<script>
    import MainLayout from "../../../layouts/MainLayout.svelte";
  import PageHeader from '../../../components/page-header/PageHeader.svelte';

    export let slug =
        "jakarta-halal-expo-siap-berkonjungsi-di-muslim-lifefest-agustus-2024";
        
</script>

<MainLayout>
    <div slot="content">
        <PageHeader title="Media & Press" />
        <div class="container mt-4">
            <div class="row">
                <div class="col-md-12 mb-4">
                    <h3 class="text-center text-dark mt-4">
                        Jakarta Halal Expo and Conference Siap Berkonjungsi<br/>di Muslim LifeFest Agustus 2024
                    </h3>
                    <img class="mt-4" src="/assets/images/press-release-2.jpg" alt="">
                </div>
                <div class="col-md-12 blog-main mt-4">
                    <div class="blog-post">
                        <!-- <h2 class="blog-post-title text-dark">Jakarta Halal Expo & Conference Siap Bergabung di Muslim Lifefest Agustus Mendatang</h2> -->
                        <!-- <p class="text-justify blog-post-meta  text-dark">
                            Kamis, 22 Februari 2024 - 06:37 WIB
                        </p> -->
                        <!-- <br/> -->
                        <p class="text-justify mb-4 text-dark">
                            Untuk memperkuat ekosistem halal dan meningkatkan eskalasi industri halal, ekonomi dan keuangan syariah, KPMI bersama dengan Lima Events  siap meluncurkan event Jakarta Halal Expo and Conference exhibition (JHEC) yang rencananya akan berkonjungsi dengan Muslim Life Fest di ICE BSD, pada 30 Agustus sampai 1 September 2024. Berkonsep Bussiness to Bussiness (B2B), JHEC akan menjadi event bisnis yang mempertemukan buyer dan seller berskala internasional. 
                            <br>
                            “JHEC ini akan menjadi oase para pelaku bisnis dalam meningkatkan jejaring dan memperkuat kapasitas manajemen bisnisnya yang berskala global. Selain pameran dagang multiproduk yang terkurasi kualitasnya, kami juga akan mengadakan serangkaian acara konferensi bisnis, seminar, workshop, business matchmaking dan misi datang antar negara,” papar Ketua KPMI Pusat Rachmat Sutarnas Marpaung.
                            <br>
                            Perkembangan situasi global yang sulit diprediksi, diakui Rachmat Marpaung, pelaku bisnis di industri halal memang harus siap beradaptasi, tidak hanya melihat peluang, tetapi juga harus memiliki ketahanan yang kuat dalam menghadapi berbagai masalah yang setiap saat terjadi. 
                            <br>
                            “Selama JHEC, kami berikhtiar meningkatkan kolaborasi dan kerja sama melalui forum-forum bisnis dari berbagai sektor. Diantaranya Halal Food & Beverage Forum, Moslem Modest Fashion Forum, General Business Forum, Halal Cosmetic & Pharmaceutical Forum, Moslem Friendly Travel Forum, Sustainable Agriculture, Islamic Finance (Fintech Start-up and Digital Banking Forum),” ungkapnya.
                            <br>
                            Untuk menambah semarak JHEC, di hari terakhir akan dibuka untuk public untuk dapat menyaksikan sekaligus mengikuti berbagai kompetisi, seperti Coffee Cupping, Cross Countries Cooking Demos, Startups Competition & pitching program, dan sebagainya. 
                            <br>
                            Harga tiket masuk Muslim Life Fair dibanderol seharga Rp 30.000. Namun pengunjung bisa mendapatkan harga lebih murah. Hanya dengan mengikuti <strong>presale tiket batch 2</strong> dari <strong>27-29 Februari 2024</strong>, pengunjung bisa mendapatkan harga tiket sebesar <strong>Rp 20.000</strong>.
                            <br>
                            Informasi lebih lengkap seputar Muslim Life Fair dan Muslim Life Fest dapat diikuti dengan di akun instagram <a href="https://instagram.com/muslimlifefair.id">@muslimlifefair.id</a> dan <a href="https://instagram.com/@indonesiamuslimlifefest">@indonesiamuslimlifefest</a>.
                        </p>
                        <p class="text-justify text-dark">
                            <strong>Tentang Penyelenggara</strong>
                        </p>
                        <p class="text-justify text-dark">
                            <strong>PT Lima Event Indonesia (Lima Events)</strong> adalah perusahaan Professional Islamic Exhibition Organizer di Indonesia yang berdiri sejak tahun 2019. Bagi kami, menjadi exhibition organizer bukanlah sekedar berbisnis, tetapi merupakan ikhtiar untuk terus menebar pesan-pesan Islami dalam setiap langkah yang kami jalani. Bervisi pada komitmen mengedepankan nilai- nilai Islami pada bisnis yang dijalani, Lima Events membangun sinergi untuk memajukan industri Islami di Indonesia. 
                            <br>
                            Komunitas Pengusaha Muslim Indonesia (KPMI) didirikan pada tahun 2010 di Bogor oleh beberapa asatidzah dan pengusaha muslim yang memiliki keinginan kuat menyebarluaskan ilmu tentang bagaimana pengusaha muslim melaksanakan dan mengelola usahanya sesuai
                            َََّهَْه َََّّهََََََّْ ر ِضَ اللَََعنهَ dan para sahabat صلََّاللَََعليهَ وسلمَ dengan contoh yang diberikan oleh Rasulullah serta generasi Islam terdahulu agar selamat dunia dan akhirat. 
                            <br>
                            KPMI berasaskan Pancasila dan Undang-Undang Dasar 1945 dan dalam menjalankan program serta kegiatannya selalu berpedoman dan berlandaskan pada Akidah Islam yang lurus dan menjalankan ketentuan-ketentuan sesuai Syari’at Islam, berdasarkan Al Quran dan Hadits yang shahih sesuai dengan pemahaman salafush shalih (ulama-ulama shaleh terdahulu). 
                            <br>
                            Saat ini anggota KPMI tersebar di 45 Korwil, 42 Korwil Kabupaten/Kota di seluruh Indonesia dan 3 Korwil di luar negeri. Anggota KPMI yang terdaftar berjumlah lebih dari 48.000 orang baik yang sudah jadi pengusaha maupun calon pengusaha. 
                        </p>
                    </div>
                    <!-- /.blog-post -->
                </div>
            </div>
        </div>
    </div>
</MainLayout>
