<script>
  import BoopAction from "../components/animation/boop/BoopAction.svelte";
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import PageHeader from "../components/page-header/PageHeader.svelte";
  import { Lightbox } from "svelte-lightbox";

  let items = [
    {
      imgSource: "/assets/images/icon-exhibition-1.png",
    },
    {
      imgSource: "/assets/images/event-conference.png",
      lightbox: "/assets/images/jhec-conference-2.jpg",
    },

    {
      imgSource: "/assets/images/icon-workshop-1.png",
    },

    {
      imgSource: "/assets/images/event-networking.png",
    },

    {
      imgSource: "/assets/images/event-special-programmes1.png",
    },
    {
      imgSource: "/assets/images/event-business-1.png",
    },
  ];

  let conferenceTopics = [
    {
      title: "Muslim & Modest Fashion Forum",
      color: "#FF5733",
    },
    {
      title: "Halal Food & Beverage Forum",
      color: "#33FF57",
    },
    {
      title: "Halal Tourism Forum",
      color: "#3357FF",
    },
    {
      title: "Creative Industry",
      color: "#3357FF",
    },
    {
      title: "Government Regulation",
      color: "#FF8C33",
    },
    {
      title: "Business Partnership",
      color: "#8C33FF",
    },
    {
      title: "Finance",
      color: "#33FFF5",
    },
  ];
  let specialShowTopics = [
    {
      title: "Start-ups Competition and pitching program",
      color: "#FF5733",
    },
    {
      title: "Cooking Demos",
      color: "#33FF57",
    },
    {
      title: "Coffee cupping competition",
      color: "#3357FF",
    },
    {
      title: "Kids Corner",
      color: "#FF33A1",
    },
    {
      title:
        "Rabat and discount program at the last day of the show (open for public)",
      color: "#FF8C33",
    },
  ];
  let exhibitorCatTopics = [
    {
      title: "Food & Beverages",
      color: "#FF5733",
    },
    {
      title: "Cosmetic & Care",
      color: "#33FF57",
    },
    {
      title: "Halal Tourism",
      color: "#3357FF",
    },
    {
      title: "Fashion Industry",
      color: "#3357FF",
    },
    {
      title: "Halal Media",
      color: "#FF8C33",
    },
    {
      title: "Trade Association",
      color: "#8C33FF",
    },
    {
      title: "Islamic Finance",
      color: "#FF3333",
    },
    {
      title: "Halal Logistic",
      color: "#FF5733",
    },
    {
      title: "Education & Training",
      color: "#33FFF5",
    },
    {
      title: "Halal Consulting",
      color: "#33FF8C",
    },
    {
      title: "Halal Lifestyle",
      color: "#FF33FF",
    },
  ];
  let workshopTopics = [
    {
      title:
        "Participate in hands-on workshops and training sessions designed to enhance your understanding of halal practices, regulations, and marketing strategies.",
      color: "#FF5733",
    },
  ];
  let networkingTopics = [
    {
      title:
        "Connect with fellow attendees, exhibitors, and industry experts during dedicated networking sessions and social events.",
      color: "#FF5733",
    },
  ];
  let businessMatchmakingTopics = [
    {
      title:
        "Last but not least, participants from various parts of the world can meet and explore business opportunities and collaboration with each other; where buyers, sellers, producers, investors, business dealers, distributors and agents in Halal Industry Sector can meet and optimize each other's benefits in International business matchmaking forum.",
      color: "#FF5733",
    },
  ];
  let lightboxProgrammaticController;
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="Event" />

    <div class="item-details-page">
      <div class="container">
        <div class="row justify-content-md-center">
          {#each items as item}
            <div class="col-lg-3 align-self-center mb-4">
              <BoopAction boopParams={{ scale: 1.12, timing: 200 }}>
                {#if item.lightbox}
                  <Lightbox
                    imagePreset={"fullscreen"}
                    bind:programmaticController={lightboxProgrammaticController}
                  >
                    <img
                      class="img-carousel rounded"
                      slot="thumbnail"
                      src={item.imgSource}
                    />
                    <img
                      src={item.lightbox}
                      alt=""
                      style="border-radius: 20px;height:auto;width:100%"
                    />
                  </Lightbox>
                {:else}
                  <img src={item.imgSource} class="rounded" alt="" />
                {/if}
              </BoopAction>
            </div>
          {/each}
        </div>
      </div>
    </div>

    <div class="item-details-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec" />
              <h2 class="text-dark"><em>Event</em> Topics</h2>
            </div>
          </div>
          <div class="col-lg-5 p-4">
            <h1 class="text-dark text-center">Conference</h1>
            <img
              src="/assets/images/about-77.jpg"
              alt=""
              style="border-radius: 20px;height:auto;width:100%"
            />
          </div>
          <div class="col-lg-7 align-self-center mt-4">
            <ul class="custom-list">
              {#each conferenceTopics as conf}
                <li class="mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.dev/svgjs"
                    ><g transform="matrix(1,0,0,1,0,0)"
                      ><path
                        d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,12,0Z"
                        fill={conf.color}
                        data-original-color="#000000ff"
                        stroke="none"
                      /></g
                    >
                  </svg>
                  <strong class="ml-2">{conf.title}</strong>
                </li>
              {/each}
            </ul>
          </div>

          <div class="col-lg-5 p-4 mt-4">
            <h1 class="text-dark text-center">Special Show</h1>
            <img
              src="/assets/images/about-82.jpg"
              alt=""
              style="border-radius: 20px;height:auto;width:100%"
            />
          </div>
          <div class="col-lg-7 align-self-center">
            <ul class="custom-list">
              {#each specialShowTopics as ss}
                <li class="mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.dev/svgjs"
                    ><g transform="matrix(1,0,0,1,0,0)"
                      ><path
                        d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,12,0Z"
                        fill={ss.color}
                        data-original-color="#000000ff"
                        stroke="none"
                      /></g
                    >
                  </svg>
                  <strong class="ml-2">{ss.title}</strong>
                </li>
              {/each}
            </ul>
          </div>

          <div class="col-lg-5 p-4 mt-4">
            <h1 class="text-dark text-center">Exhibitor Categories</h1>
            <img
              src="/assets/images/about-47.jpg"
              alt=""
              style="border-radius: 20px;height:auto;width:100%"
            />
          </div>
          <div class="col-lg-7 align-self-center mt-4">
            {#if exhibitorCatTopics.length > 8}
              <div class="row">
                <div class="col-lg-6">
                  <ul class="custom-list">
                    {#each exhibitorCatTopics.slice(0, 8) as ec}
                      <li class="mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          version="1.1"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xmlns:svgjs="http://svgjs.dev/svgjs"
                          ><g transform="matrix(1,0,0,1,0,0)"
                            ><path
                              d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,0Z"
                              fill={ec.color}
                              data-original-color="#000000ff"
                              stroke="none"
                            /></g
                          >
                        </svg>
                        <strong class="ml-2">{ec.title}</strong>
                      </li>
                    {/each}
                  </ul>
                </div>
                <div class="col-lg-6">
                  <ul class="custom-list">
                    {#each exhibitorCatTopics.slice(8) as ec}
                      <li class="mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          version="1.1"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xmlns:svgjs="http://svgjs.dev/svgjs"
                          ><g transform="matrix(1,0,0,1,0,0)"
                            ><path
                              d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,0Z"
                              fill={ec.color}
                              data-original-color="#000000ff"
                              stroke="none"
                            /></g
                          >
                        </svg>
                        <strong class="ml-2">{ec.title}</strong>
                      </li>
                    {/each}
                  </ul>
                </div>
              </div>
            {:else}
              <ul class="custom-list">
                {#each exhibitorCatTopics as ec}
                  <li class="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      version="1.1"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xmlns:svgjs="http://svgjs.dev/svgjs"
                      ><g transform="matrix(1,0,0,1,0,0)"
                        ><path
                          d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,0Z"
                          fill={ec.color}
                          data-original-color="#000000ff"
                          stroke="none"
                        /></g
                      >
                    </svg>
                    <strong class="ml-2">{ec.title}</strong>
                  </li>
                {/each}
              </ul>
            {/if}
          </div>

          <div class="col-lg-5 p-4 mt-4">
            <h1 class="text-dark text-center">Workshop</h1>
            <img
              src="/assets/images/about-26.jpg"
              alt=""
              style="border-radius: 20px;height:auto;width:100%"
            />
          </div>
          <div class="col-lg-7 align-self-center">
            <ul class="custom-list">
              {#each workshopTopics as xxx}
                <li class="mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.dev/svgjs"
                    ><g transform="matrix(1,0,0,1,0,0)"
                      ><path
                        d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,12,0Z"
                        fill={xxx.color}
                        data-original-color="#000000ff"
                        stroke="none"
                      /></g
                    >
                  </svg>
                  <strong class="ml-2">{xxx.title}</strong>
                </li>
              {/each}
            </ul>
          </div>

          <div class="col-lg-5 p-4 mt-4">
            <h1 class="text-dark text-center">Networking</h1>
            <img
              src="/assets/images/about-88.jpg"
              alt=""
              style="border-radius: 20px;height:auto;width:100%"
            />
          </div>
          <div class="col-lg-7 align-self-center">
            <ul class="custom-list">
              {#each networkingTopics as xxx}
                <li class="mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.dev/svgjs"
                    ><g transform="matrix(1,0,0,1,0,0)"
                      ><path
                        d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,12,0Z"
                        fill={xxx.color}
                        data-original-color="#000000ff"
                        stroke="none"
                      /></g
                    >
                  </svg>
                  <strong class="ml-2">{xxx.title}</strong>
                </li>
              {/each}
            </ul>
          </div>

          <div class="col-lg-5 p-4 mt-4">
            <h1 class="text-dark text-center">Business Matchmaking</h1>
            <img
              src="/assets/images/about-93.jpg"
              alt=""
              style="border-radius: 20px;height:auto;width:100%"
            />
          </div>
          <div class="col-lg-7 align-self-center">
            <ul class="custom-list">
              {#each businessMatchmakingTopics as xxx}
                <li class="mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    version="1.1"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.dev/svgjs"
                    ><g transform="matrix(1,0,0,1,0,0)"
                      ><path
                        d="M12,4.243,19.757,12,12,19.757,4.243,12,12,4.243M12,0,0,12,12,24,24,12,12,0Z"
                        fill={xxx.color}
                        data-original-color="#000000ff"
                        stroke="none"
                      /></g
                    >
                  </svg>
                  <strong class="ml-2">{xxx.title}</strong>
                </li>
              {/each}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</MainLayout>
