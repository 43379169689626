<script>
  import ContactSection from "../components/chunks/ContactSection.svelte";
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import "animate.css";
  import { 
        Lightbox,
    } from 'svelte-lightbox'
  import PageHeader from '../components/page-header/PageHeader.svelte';

  let facilities = [
    {
      title: "Fascia",
      desc: "Exhibitor's name (max 30 letters) in white vinyl lettering",
    },
    {
      title: "Carpet",
      desc: "Dark grey covering inside Booth",
    },
    {
      title: "Paneling",
      desc: "Three-side panel partitions in whitefinish. In case of corner booths. it will be two side walls and two fascia boards",
    },
    {
      title: "Furniture",
      desc: "Information counter with one reception desk, two folding chairs, and one of wastepaper basket",
    },
    {
      title: "Power",
      desc: "One power single phase socket with electricity 2 amp (not to be used to connect spot lights & fluorescent lights)",
    },
    {
      title: "Lighting",
      desc: "Two units of fluorescent lamps at fascia",
    },
  ]
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="Exhibit" />

    <div class="item-details-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading pb-0">
              <div class="line-dec" />
              <h2 class="text-dark">Book Your Stand.</h2>
            </div>
            <h3
              class="text-center text-primary animate__animated animate__infinite animate__pulse"
            >
              Exhibit with Jakarta Halal Expo and Conference<br/>and<br/> Uniting the
              World Through Halal Excellence
            </h3>
          </div>
          <div class="col-lg-5 col-sm-12 align-self-center mb-4">
            <h4 class="text-dark">Plan Your Stand</h4>
            <p class="text-dark">
              Join the Jakarta Halal Expo and Conference to explore a wide range of halal products, from modest fashion and cosmetics to food, beverages, and business solutions. Discover new opportunities, form partnerships, and elevate your business. Enjoy insightful panel discussions, engaging conferences, and networking sessions with halal market professionals from around the world.
              Reserve your spot now and showcase your products at this premier event!.
            </p>
          </div>
          <div class="col-lg-7 col-sm-12 mb-4">
            <div class="right-image">
              <img
                src="/assets/images/about-7.jpg"
                alt=""
                style="border-radius: 20px;"
              />
            </div>
          </div>

          <div class="col-lg-12 mb-4">
            <h4 class="text-dark">Floor Plan</h4>
            <p class="text-dark text-center">
              Exhibitor Area Indonesia Convention Exhibition (ICE) BSD,
              Tangerang Hall 9
              <br />
              Secure Your Space!
              <span>(<a href="/contact">Link</a>)</span>
            </p>
            <div class="center-image-zoom">
              <Lightbox>
                <img slot="trigger" src="/assets/images/exhibit-0.png" alt="" style="border-radius: 20px;" />
                <img src="/assets/images/exhibit-0.png" alt="" />
              </Lightbox>
              <center>
                <div class="main-button">
                  <a href="https://bit.ly/FloorplanJHEC2024">Download Floor Plan</a>
                </div>
              </center>
            </div>
          </div>
          <div class="exh-sc-facilities p-4">
            <h4 class="txt-title text-dark">Stand Construction</h4>
            <div class="exh-sc">
              <div class="row">
                <div class="col-md-6 col-xs-12 animate__animated animate__fadeInLeft animate__slow">
                  <h6 class="text-dark text-center">
                    Option 1 : Space Only
                  </h6>
                </div>
                <div class="col-md-6 col-xs-12 animate__animated animate__fadeInRight animate__slow">
                  <h6 class="text-dark text-center">
                    Option 2 : Standard Booth
                  </h6>
                </div>
                <div class="center-image animate__animated animate__fadeInDown animate__slow">
                  <img
                    src="/assets/images/exhibit-stand.png"
                    alt=""
                    style="border-radius: 20px;"
                  />
                </div>
              </div>
            </div>
            <div class="exh-facilities">
              <div class="row">
                <div class="col-lg-2 col-sm-12 animate__animated animate__fadeInUp animate__slow">
                  <h4 class="txt-title text-dark text-left mt-4">Facilities : </h4>
                </div>
                <div class="col-lg-10 col-sm-12 mt-4">
                  <div class="row">
                    {#each facilities as item}
                    <div class="item-facilities pt-4 col-md-6 animate__animated animate__fadeInUp animate__slow">
                      <h6 class="text-dark">{item.title}</h6>
                      <p class="text-dark">
                        {item.desc}
                      </p>
                    </div>
                    {/each}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>         
      </div>
    </div>
  </div>
</MainLayout>

<style>
  .center-image {
    display: flex;
    justify-content: center;
  }

  .center-image img {
    width: 50%;
    border-radius: 20px;
  }

  .center-image-zoom {
    text-align: center;
    margin: 0 auto;
    width: 50%;
}

.exh-sc-facilities{
  background-image: url(/assets/images/dark-bg-2.png);
  background-repeat: repeat;
  background-position: center center;
  background-size: auto;
  position: relative;
  border-bottom-left-radius: 1%;
  border-bottom-right-radius: 5%;
}

.exh-sc{
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: white;
  border: 2px solid #6e63a7;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.item-facilities{
  background-color: white;
  border:2px solid #113552;
  box-sizing: border-box;
  border-bottom-right-radius: 10%;
  border-top-right-radius: 2%;
}

.txt-title{
  background-color: white;
  border:2px solid #113552;
  border-bottom-right-radius: 10%;
  border-top-right-radius: 2%;
  padding: 10px; 
  display: inline-block;
}
</style>