<script>
  import { Router, Route } from "svelte-navigator";
  import LinkPage from "./LinkPage.svelte";
  import HomePage from "pages/home/HomePage.svelte";
  import AboutPage from "pages/AboutPage.svelte";
  import ContactPage from "pages/ContactPage.svelte";
  import EventPage from "pages/EventPage.svelte";
  import ExhibitPage from "pages/ExhibitPage.svelte";
  import MediaPage from "pages/MediaPage.svelte";
  import PartnersPage from "pages/PartnersPage.svelte";
  import VisitPage from "pages/VisitPage.svelte";
  import ProductsPage from "./pages/products/ProductsPage.svelte";
  import { locale } from "config";
  import { onMount } from "svelte";

  import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
    import News from "./pages/media/press/News.svelte";
  import News2 from "./pages/media/press/News2.svelte";
  addMessages("en", locale.en);
  addMessages("id", locale.id);
  init({
    fallbackLocale: "id",
    initialLocale: getLocaleFromNavigator(),
  });
  
</script>

<Router>
  <Route path="/"><HomePage /></Route>
  <Route path="/about"><AboutPage /></Route>
  <Route path="/contact"><ContactPage /></Route>
  <Route path="/event"><EventPage /></Route>
  <Route path="/exhibit"><ExhibitPage /></Route>
  <Route path="/media"><MediaPage /></Route>
  <Route path="/partners"><PartnersPage /></Route>
  <Route path="/visit"><VisitPage /></Route>
  <Route path="/media/press/jakarta-halal-expo-and-conference-siap-bergabung-di-muslim-lifefest-agustus-mendatang"><News /></Route>
  <Route path="/media/press/jakarta-halal-expo-siap-berkonjungsi-di-muslim-lifefest-agustus-2024"><News2 /></Route>
</Router>
