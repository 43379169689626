import App from './Router.svelte';

let target = document.getElementById('target');

const app = new App({
	target: target,
	props: {
		
	}
});

export default app;