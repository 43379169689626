<script>
  import Carousel from "svelte-carousel";
  import { onMount } from "svelte";

  let a = 4;
  let b = 2;
  onMount(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 780 && width > 601) {
        a = 3;
        b = 1;
      }
      if (width < 600) {
        a = 1;
        b = 1;
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  export let prefix = "about-";
  export let imageFromAndTo = [1, 10];
  export let autoplayDuration = 10000

  import { 
        Lightbox,
    } from 'svelte-lightbox'
    
    let lightboxProgrammaticController
</script>

<Carousel
  particlesToShow={a}
  particlesToScroll={b}
  arrows={false}
  autoplay
  autoplayDuration={autoplayDuration}
  dots={false}
>
  {#each { length: imageFromAndTo[1] - imageFromAndTo[0] } as _, i}
    <div class="container-carousel">
      <Lightbox imagePreset={"fullscreen"}>
        <img class="img-carousel" slot="thumbnail" src="/assets/images/{prefix}{i + imageFromAndTo[0]}.jpg" alt="thumbnail-{prefix}{i + imageFromAndTo[0]}">
        <img src="/assets/images/{prefix}{i + imageFromAndTo[0]}.jpg" alt="{prefix}{i + imageFromAndTo[0]}">    
    </Lightbox>
      
    </div>
  {/each}
</Carousel>

<style>
  .container-carousel {
    display: flex;
    min-height: 250px;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    text-align: center;
  }

  .img-carousel {
    max-width: 100%;
    max-height: 250px;
    object-fit: contain;
    padding-right: 5px;
    padding-left: 5px;
  }

  .container-carousel:hover .img-carousel {
    opacity: 1;
  }

  .container-carousel .img-carousel {
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }

  .img-carousel:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 600px) {
    .container-carousel {
      display: flex !important;
      overflow: hidden !important;
      width: calc(100% * 2) !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      align-self: center !important;
      text-align: center !important;
    }

    .img-carousel {
      flex-shrink: 0;
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .img-carousel:first-of-type {
      margin-left: 0 !important;
    }

    .img-carousel:last-of-type {
      margin-right: 0 !important;
    }
  }
</style>
