<script>
  import PressReleaseCarousel from "../components/carousel/PressReleaseCarousel.svelte";
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import PageHeader from '../components/page-header/PageHeader.svelte';
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="Media & Press" />

    <div class="discover-items pb-0 ">
      <div class="container">
        <div class="row mb-2">
          <div class="col-lg-12">
            <div class="section-heading mb-4 pb-0">
              <div class="line-dec" />
              <h2 class="text-dark ">Press <em>Release</em></h2>
            </div>
          </div>
          <div class="col-md-12 ">

            <div class="card flex-md-row mb-4 box-shadow h-md-250 row">
            <div class="col-md-8">
                <div class="card-body d-flex flex-column align-items-start">
                  <!-- <strong class="d-inline-block mb-2 text-primary">World</strong> -->
                  <h3 class="mb-0">
                    <a class="text-dark" href="/media/press/jakarta-halal-expo-and-conference-siap-bergabung-di-muslim-lifefest-agustus-mendatang"
                      >
                      Muslim Life Fair 2024 Suguhkan <i>One Stop Solution</i>
                        <br>
                        Keluarga Muslim Sambut Ramadan 1445 H
                      </a
                    >
                  </h3>
                  <div class="mb-1 text-muted">Jakarta, 18 Februari 2024</div>
                  <p class="card-text text-dark mb-auto">
                    Pengunjung keluarga yang mendominasi pameran Muslim Life Fair dalam dua tahun terakhir mendorong Lima Event bersama Komunitas Pengusaha Muslim Indonesia (KPMI) menggelar event  bertajuk “Pesta Keluarga Muslim” yang siap diselenggarakan di Hall B Jakarta International Expo (JIEXPO), Kemayoran pada 8-10 Maret 2024....
                  </p>
                  <a
                    href="/media/press/jakarta-halal-expo-and-conference-siap-bergabung-di-muslim-lifefest-agustus-mendatang"
                    >Continue reading</a
                  >
                </div>
              
            </div>
            <div class="col-md-4" style="background-size:cover;background-image: url('/assets/images/press-release-1.jpg');">
            </div>
            </div>
          </div>

          <div class="col-md-12 ">

            <div class="card flex-md-row mb-4 box-shadow h-md-250 row">
            <div class="col-md-8">
                <div class="card-body d-flex flex-column align-items-start">
                  <!-- <strong class="d-inline-block mb-2 text-primary">World</strong> -->
                  <h3 class="mb-0">
                    <a class="text-dark" href="/media/press/jakarta-halal-expo-siap-berkonjungsi-di-muslim-lifefest-agustus-2024"
                      >
                      Jakarta Halal Expo and Conference Siap Berkonjungsi <br> di Muslim LifeFest Agustus 2024
                      </a
                    >
                  </h3>
                  <div class="mb-1 text-muted">Jakarta, 18 Februari 2024</div>
                  <p class="card-text text-dark mb-auto">
                    Untuk memperkuat ekosistem halal dan meningkatkan eskalasi industri halal, ekonomi dan keuangan syariah, KPMI bersama dengan Lima Events siap meluncurkan event Jakarta Halal Expo and Conference exhibition (JHEC) yang rencananya akan berkonjungsi dengan Muslim Life Fest di ICE BSD, pada 30 Agustus sampai 1 September 2024. Berkonsep Bussiness to Bussiness (B2B), JHEC akan menjadi ....
                  </p>
                  <a
                    href="/media/press/jakarta-halal-expo-siap-berkonjungsi-di-muslim-lifefest-agustus-2024"
                    >Continue reading</a
                  >
                </div>
              
            </div>
            <div class="col-md-4" style="background-size:cover;background-image: url('/assets/images/press-release-2.jpg');">
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="container mt-4">
      <div class="row justify-content-md-center">
        <div class="col-lg-12">
          <div class="section-heading pb-0 mb-1">
            <div class="line-dec" />
            <h2 class="text-dark">Registration Media & Press</h2>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <center>
            <div class="main-button">
              <a target="_blank noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSciyEELvLXf-Et7lnHKI-Vx6634J1jjS1h-IONOXpT99VCxXw/viewform?embedded=true">Register Now</a>
            </div>
          </center>
        </div>
      </div>
    </div>

   
    <!-- 
  <div class="discover-items pb-4 mb-4">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-12">
          <div class="section-heading">
            <div class="line-dec" />
            <h2 class="text-dark">Address</h2>
          </div>
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-6">
          PT. Lima Event Indonesia<br/>Graha Inti Fauzi, Jl. Buncit Raya No.22 9th
          Floor, RT.2/RW.7, <br/>Pejaten Bar., Ps. Minggu, <br/>Kota Jakarta Selatan,
          Daerah Khusus Ibukota Jakarta 12510
          <br/><br/>
          <a href="https://www.google.com/maps/place/LIMA+EVENT+INDONESIA/@-6.2757015,106.8295198,15z/data=!4m15!1m8!3m7!1s0x2e69f37888a8a21d:0x10d0c647a2460da2!2sLIMA+EVENT+INDONESIA!8m2!3d-6.275844!4d106.8295557!10e5!16s%2Fg%2F11fpq31x6q!3m5!1s0x2e69f37888a8a21d:0x10d0c647a2460da2!8m2!3d-6.275844!4d106.8295557!16s%2Fg%2F11fpq31x6q?entry=ttu"><img src="/assets/images/icon-google-maps.png" style="height: 48px; width:auto" class="mr-1"/> Buka di Google Maps</a>
          <br/><br/><a href="https://www.waze.com/en/live-map/directions?latlng=-6.2757015%2C106.8295198"><img src="/assets/images/icon-waze.png" class="mr-1 d-inline" style="height: 48px; width:auto"/> Buka di Waze</a>            
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-6">
          <div style="width: 100%">
            <iframe
              width="100%"
              height="200"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Graha%20Inti%20Fauzi,%20%20Jl.%20Buncit%20Raya%20No.22%209th%20Floor,%20RT.2/RW.7,%20Pejaten%20Bar.,%20Ps.%20Minggu,%20%20Kota%20Jakarta%20Selatan,%20Daerah%20Khusus%20Ibukota%20Jakarta%2012510et,%20Dublin,%20Ireland+(Lima%20Event%20-%20JHEC%20Office)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ><a href="https://www.gps.ie/">gps systems</a></iframe
            >
          </div>
        </div>
      </div>
    </div>
  </div> -->
  </div>
</MainLayout>
