<script>
  import { _ } from "svelte-i18n";
  // import Countup from "svelte-countup";
  import "animate.css";
  // import { fade, fly } from "svelte/transition";
  // import {Youtube}  from 'svelte-youtube-lite';
  let visible = false;
  let items = [
    {
      title: "Food & Beverages",
      icon: "cat_fnb.webp",
      link: "#",
    },
    {
      title: "Cosmetic & Care",
      icon: "cat_cosmetic.webp",
      link: "#",
    },
    {
      title: "Halal Tourism",
      icon: "cat_tourism.webp",
      link: "#",
    },
    {
      title: "Fashion Industry",
      icon: "cat_fashion.webp",
      link: "#",
    },
    {
      title: "Halal Media",
      icon: "cat_media.webp",
      link: "#",
    },
    {
      title: "Trade Association",
      icon: "cat_trade.webp",
      link: "#",
    },
    {
      title: "Islamic Finance",
      icon: "cat_islamic.webp",
      link: "#",
    },
    {
      title: "Halal Logistic",
      icon: "cat_logistic.webp",
      link: "#",
    },
    {
      title: "Education & Training",
      icon: "cat_edu.webp",
      link: "#",
    },
    {
      title: "Halal Consulting",
      icon: "cat_consulting.webp",
      link: "#",
    },
    {
      title: "Halal Lifestyle",
      icon: "cat_lifestyle.webp",
      link: "#",
    },
  ];
  let eventPartners = [
    {
      src: "/assets/images/CMC.png",
      url: "https://www.instagram.com/capemalayconsultants/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Chile Halal.png",
      url: "https://www.chilehalal.com/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Connect_Souq.webp",
      url: "https://www.connectsouq.com/",
      style: "height:50px",
    },
    {
      src: "/assets/images/partners/GAHSB.webp",
      url: "https://geoartha.com.my/",
      style: "height:50px",
    },
    {
      src: "/assets/images/partners/Geo_Artha.webp",
      url: "https://geoartha.com.my/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/GICTC.webp",
      url: "https://www.gictc.world/index.html",
      style: "height:48px",
    },
    {
      src: "/assets/images/partners/Halal_Bosnia.png",
      url: "https://www.halal.ba/index.php/en/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Halal_Control_Germany.png",
      url: "https://halalcontrol.de/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/HICCIP_Philippine_Chamber.webp",
      url: "https://halalchamber.com.ph/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/JHCPO.webp",
      url: "https://jhcpo.com/",
      style: "height:38px",
    },
    {
      src: "/assets/images/partners/MITF.webp",
      url: "https://geoartha.com.my/",
      style: "height:55px",
    },
    {
      src: "/assets/images/partners/Musiad.png",
      url: "https://www.halalitalia.org/",
    },
    {
      src: "/assets/images/Halal Italia.jpeg",
      url: "https://www.musiad.org.tr/en",
    },
    {
      src: "/assets/images/partners/NHASA.webp",
      url: "https://nhasa.com.au/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/uwhd.webp",
      url: "https://www.unwhd.com",
      style: "height:48px",
    },
    {
      src: "/assets/images/partners/whd.webp",
      url: "https://www.worldhalalday.com",

      style: "height:60px",
    },
  ];

  let internationalMediaPartners = [
    {},
    {
      src: "/assets/images/Halal Focus.jpeg",
      url: "https://halalfocus.net/",
      style: "height:60px",
    },
    {
      src: "/assets/images/HRC Logo.png",
      url: "https://halalrc.org/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Halal_Flash.webp",
      url: "https://www.halalflash.com/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Halal Weekly.png",
      url: "https://www.halalweekly.com/",
      style: "height:60px",
    },
    {},
    {
      src: "/assets/images/partners/Maldives_Halal_Travel.png",
      url: "https://www.maldiveshalaltravel.com/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/SMIIC.webp",
      url: "https://www.smiic.org/",
      style: "height:36px",
    },
    {
      src: "/assets/images/Trade Fair Dates.JPG",
      url: "https://www.tradefairdates.com/",
      style: "height:60px",
    },
  ];

  let mediaPartners = [
    {
      src: "/assets/images/Radio Celebrities.png",
      url: "https://www.instagram.com/vradiofm",
      style: "height:54px",
    },
    {
      src: "/assets/images/Farah Magazine.png",
      url: "https://www.farah.id/",
      style: "height:60px",
    },
    {
      src: "/assets/images/Logo HIGO.png",
      url: "https://higo.id/",
      style: "height:24px",
    },
    {
      src: "/assets/images/Indonesia Window.jpeg",
      url: "https://indonesiawindow.com/en/",
    },
    { src: "/assets/images/iNews.id.png", url: "https://www.inews.id/" },
    {
      src: "/assets/images/iNews TV.png",
      url: "https://www.inews.id/streaming",
    },
    {
      src: "/assets/images/Inilah-com.png",
      url: "https://inilah.com/",
      style: "height:32px",
    },
    {
      src: "/assets/images/partners/Katalog_Promosi.webp",
      url: "https://katalogpromosi.com/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Koran_Indopos.webp",
      url: "https://koranindopos.com/",
      style: "height:32px",
    },
    {
      src: "/assets/images/partners/Langit7.webp",
      url: "https://langit7.id/",
      style: "height:50px",
    },
    {
      src: "/assets/images/partners/Marketeers.png",
      url: "https://www.marketeers.com/",
    },
    {
      src: "/assets/images/Men Obsession.png",
      url: "https://www.mensobsession.com/",
      style: "height:32px",
    },
    {
      src: "/assets/images/MNC TRIJAYA FM.PNG",
      url: "https://www.mnctrijaya.com/",
    },
    {
      src: "/assets/images/Obsession News.png",
      url: "https://www.obsessionnews.com/",
    },
    { src: "/assets/images/Okezone.png", url: "https://www.okezone.com/" },
    {
      src: "/assets/images/Radio RDI.png",
      url: "https://www.obsessionnews.com/",
      style: "height:48px",
    },
    {
      src: "/assets/images/partners/Publica_FM.webp",
      url: "https://publicafm.com/",
      style: "height:48px",
    },
    {
      src: "/assets/images/partners/Satu_Indonesia.webp",
      url: "https://satuindonesia.co/",
      style: "height:70px",
    },
    {
      src: "/assets/images/Sindonews TV.png",
      url: "https://www.sindonews.com/",
      style: "height:32px",
    },
    {
      src: "/assets/images/partners/Smart_FM.webp",
      url: "https://www.radiosmartfm.com/",
      style: "height:60px",
    },
    {
      src: "/assets/images/partners/Suara.webp",
      url: "https://www.suara.com/",
      style: "height:30px",
    },
    {
      src: "/assets/images/partners/Viva.webp",
      url: "https://www.viva.co.id/",
      style: "height:48px",
    },
    {
      src: "/assets/images/Women Obsession 1.png",
      url: "https://www.womensobsession.com/",
      style: "height:60px",
    },
  ];

  import MainLayout from "layouts/MainLayout.svelte";
  import BoopActionHold from "../../components/animation/boop/BoopActionHold.svelte";
  import HeroCarousel from "../../components/carousel/HeroCarousel.svelte";
  import LiteYoutubeEmbed from "../../components/youtube/LiteYoutubeEmbed.svelte";
  setTimeout(() => {
    visible = true;
  }, 100);
</script>

<MainLayout>
  <div slot="content">
    <!-- <div class="container-fluid main-banner">
     
    </div> -->
    <!-- ***** Main Banner Area Start ***** -->
    <div class="main-banner">
      <HeroCarousel autoplayDuration={6000} />
    </div>

    <!-- 
    <div class="container">
      <div class="row">
        <div class="col-lg-6 align-self-center">
          <div class="header-text">
            <h6>30 Aug - 1 Sept 2024</h6>
            <h2>The largest Halal Exhibition and Conference in Indonesia</h2>
            <p>
              Empowering Global Business Networks, JHEC Set to Transform
              Management Capacities, Creating a Thriving Oasis for
              Entrepreneurs. JHEC is poised to become a vital resource for
              business professionals, providing a platform to expand networks
              and strengthen global business management skills.
            </p>
            <div class="buttons">
              <div class="border-button">
                <a href="#preview">Learn More</a>
              </div>
              <div class="main-button">
                <a
                  href="https://www.youtube.com/@MuslimLifeFestOfficial"
                  target="_blank">Watch Our Videos</a
                >
              </div>
            </div>
          </div>
        </div>
       <div class="col-lg-5 offset-lg-1">
          <div class="owl-banner owl-carousel">
            <div class="item">
              <img src="/assets/images/about-7.jpg" alt="" />
            </div>
            <div class="item">
              <img src="/assets/images/about-8.jpg" alt="" />
            </div>
            <div class="item">
              <img src="/assets/images/about-9.jpg" alt="" />
            </div>
          </div>
        </div> </div>
    </div> -->
    <!-- ***** Main Banner Area End ***** -->
    <div id="preview" class="item-details-page">
      <div class="container">
        <div class="row  justify-content-md-center">
          <div class="col-md-12">
            <!-- <div class="main-timeline8">
              <div class="timeline">
                <span class="timeline-icon" />
                <span class="year">In 2022</span>
                <div class="timeline-content">
                  <p class="description">
                    We started this B2B event with the name of Muslim Life
                    Trade, at that time there were more than 400 exhibitors
                    joining as tenants at the exhibition. Among them are 18
                    International Pavilions from 18 countries. The opening event
                    was attended by more than 35 Ambassadors and Country
                    Representatives of friendly countries based in Jakarta. This
                    event were visited by more than 45,000 people from various
                    nationalities
                  </p>
                </div>
              </div>
              <div class="timeline">
                <span class="timeline-icon" />
                <span class="year">In 2023</span>
                <div class="timeline-content">
                  <p class="description">
                    We held the second B2B event in collaboration with
                    Indonesian Central Bank (BI), and PT. Halal Expo Indonesia.
                    This event hosted more than 100 exhibitors, in which more
                    than 20 countries sent participants to take part in the
                    exhibition.
                  </p>
                  <br />
                  <br />
                </div>
              </div>
              <div class="timeline">
                <span class="timeline-icon" />
                <span class="year">In 2024</span>
                <div class="timeline-content">
                  <p class="description">
                    The first Jakarta Halal Expo and Conference will be held in
                    ICE BSD, 30 August - 1 September 2024, with various business
                    categories and programmes.
                  </p>
                </div>
              </div>
            </div> -->

            <div class="timeline-container">
              <div class="timeline-box timeline-box-2022">
                <div class="box-number">1</div>
                <div class="box-content">
                  <span class="year">In 2022</span>
                  <p class="description">
                    We started this B2B event with the name of Muslim Life
                    Trade, at that time there were more than 400 exhibitors
                    joining as tenants at the exhibition. Among them are 18
                    International Pavilions from 18 countries. The opening event
                    was attended by more than 35 Ambassadors and Country
                    Representatives of friendly countries based in Jakarta. This
                    event were visited by more than 45,000 people from various
                    nationalities.
                  </p>
                </div>
              </div>
              <div class="arrow-container">
                <div class="arrow">&#9654;</div>
              </div>
              <div class="timeline-box timeline-box-2023">
                <div class="box-number">2</div>
                <div class="box-content">
                  <span class="year">In 2023</span>
                  <p class="description">
                    We held the second B2B event in collaboration with
                    Indonesian Central Bank (BI), and PT. Halal Expo Indonesia.
                    This event hosted more than 100 exhibitors, in which more
                    than 20 countries sent participants to take part in the
                    exhibition.
                  </p>
                </div>
              </div>
              <div class="arrow-container">
                <div class="arrow">&#9654;</div>
              </div>
              <div class="timeline-box timeline-box-2024">
                <div class="box-number">3</div>
                <div class="box-content">
                  <span class="year">In 2024</span>
                  <p class="description">
                    The first Jakarta Halal Expo and Conference will be held in
                    ICE BSD, 30 August - 1 September 2024, with various business
                    categories and programmes.
                  </p>
                </div>
              </div>
            </div>

            <!-- <center>
              <div class="main-button">
                <a href="https://www.youtube.com/watch?v=rYAVEkMwdp0"
                  ><i class="fa fa-video" /> Watch After Movie</a
                >
              </div>
            </center> -->
          </div>
          <div class="col-lg-6 col-xs-12 mb-4 mt-4">
            <center
              class="animate__animated animate__backInLeft animate__delay-1s animate__slow"
            >
              <LiteYoutubeEmbed
                videoId="rYAVEkMwdp0"
                posterImageSrc="/assets/images/videos/aftermovie1.webp"
                params="modestbranding=1"
              />
              <!-- <Youtube id="rYAVEkMwdp0"  /> -->
              <!-- <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rYAVEkMwdp0?si=bWtIQmaUeuKF09zm"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              /> -->
            </center>
          </div>
          <div class="col-lg-6 col-xs-12 mb-4 mt-4">
            <center
              class="animate__animated animate__backInRight animate__delay-1s animate__slow"
            >
              <LiteYoutubeEmbed
                videoId="EdSUxoBG96Q"
                posterImageSrc="/assets/images/videos/aftermovie2.webp"
                params="modestbranding=1"
              />
              <!-- <Youtube id="EdSUxoBG96Q" showTitle="{false}" /> -->
              <!-- <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/EdSUxoBG96Q"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              /> -->
            </center>
          </div>
        </div>
      </div>
    </div>
    <div id="categories-collections" class="categories-collections">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="categories">
              <div class="row justify-content-md-center">
                <div class="col-lg-12">
                  <div class="section-heading">
                    <div class="line-dec" />
                    <h2>Exhibitor <em>Categories</em>.</h2>
                  </div>
                </div>
                {#each items as item, i}
                  <div class="col-lg-3 col-sm-6 mb-1">
                    <div class="item">
                      <BoopActionHold boopParams={{ scale: 1.2, timing: 100 }}>
                        <div class="icon">
                          <img
                            loading="lazy"
                            src="/assets/images/{item.icon}"
                            alt=""
                          />
                        </div>
                        <h4>{item.title}</h4>
                        <!-- <div class="icon-button">
                      <a href={item.link}><i class="fa fa-angle-right" /></a>
                    </div> -->
                      </BoopActionHold>
                    </div>
                  </div>
                {/each}
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <div class="section-heading mb-5">
              <div class="line-dec" />
              <h2>About <em>the Event!</em></h2>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 mb-4">
            <div class="item first-item">
              <div class="icon">
                <i
                  class="fa fa-calendar animate__animated animate__infinite animate__swing"
                  style="color:#6e63a7"
                />
              </div>
              <h4
                style="display:inline-block; margin-left:8px;"
                class="animate__animated animate__infinite animate__fadeInRight animate__slow"
              >
                30 Aug - 01 Sept 2024
              </h4>
            </div>
          </div>
          <div class="col-lg-6  col-xs-12 mb-4">
            <div class="item second-item">
              <div class="icon">
                <i
                  class="fa fa-th animate__animated animate__infinite animate__swing"
                  style="color:#6e63a7"
                />
              </div>
              <h4
                style="display:inline-block;margin-left:8px;"
                class="animate__animated animate__infinite animate__fadeInRight animate__slow"
              >
                1000 SQM / 22 BOOTH
              </h4>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 mb-4">
            <div class="item first-item">
              <div class="icon">
                <i
                  class="fa fa-map-marker animate__animated animate__infinite animate__swing"
                  style="color:#6e63a7"
                />
              </div>
              <h4
                style="display:inline-block;margin-left:8px;"
                class="animate__animated animate__infinite animate__fadeInRight animate__slow"
              >
                Hall 9 - ICE BSD CITY
              </h4>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 mb-4">
            <div class="item second-item">
              <div class="icon">
                <i
                  class="fa fa-users animate__animated animate__infinite animate__swing"
                  style="color:#6e63a7"
                />
              </div>
              <h4
                style="display:inline-block;margin-left:8px;"
                class="animate__animated animate__infinite animate__fadeInRight animate__slow"
              >
                50,000 VISITOR FOR 3 DAYS
              </h4>
            </div>
          </div>
          <!-- New Info Section -->
          <div class="container col-lg-12 mt-4 d-flex justify-content-center">
            <div class="row w-100 justify-content-center">
              <div class="col-lg-5  col-xs-12 mx-3 my-2">
                <div class="item-event info-item">
                  <div class="info-text">
                    <h4 class="text-dark">TRADE DAYS</h4>
                    <p class="text-dark">
                      <i
                        class="fa fa-calendar animate__animated animate__infinite animate__swing"
                        style="color:#6e63a7"
                      />
                      30 August 2024
                    </p>
                    <p class="text-dark">
                      <i
                        class="fa fa-clock animate__animated animate__infinite animate__swing"
                        style="color:#6e63a7"
                      />
                      10.00 a.m. - 06.00 p.m.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xs-12 mx-3 my-2">
                <div class="item-event info-item">
                  <div class="info-text">
                    <h4 class="text-dark">PUBLIC DAYS</h4>
                    <p class="text-dark">
                      <i
                        class="fa fa-calendar animate__animated animate__infinite animate__swing"
                        style="color:#6e63a7"
                      />
                      31 Aug - 01 Sept 2024
                    </p>
                    <p class="text-dark">
                      <i
                        class="fa fa-clock animate__animated animate__infinite animate__swing"
                        style="color:#6e63a7"
                      />
                      10.00 a.m. - 09.00 p.m.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-12">
          <div class="collections">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-heading">
                  <div class="line-dec"></div>
                  <h2>Explore Some Hot <em>Collections</em> In Market.</h2>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="owl-collection owl-carousel">
                  <div class="item">
                    <img src="/assets/images/collection-01.jpg" alt="">
                    <div class="down-content">
                      <h4>Mutant Bored Ape Yacht Club</h4>
                      <span class="collection">Items In Collection:<br><strong>310/340</strong></span>
                      <span class="category">Category:<br><strong>Digital Crypto</strong></span>
                      <div class="main-button">
                        <a href="explore.html">Explore Mutant</a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <img src="/assets/images/collection-01.jpg" alt="">
                    <div class="down-content">
                      <h4>Bored Ape Kennel Club</h4>
                      <span class="collection">Items In Collection:<br><strong>324/324</strong></span>
                      <span class="category">Category:<br><strong>Visual Art</strong></span>
                      <div class="main-button">
                        <a href="explore.html">Explore Bored Ape</a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <img src="/assets/images/collection-01.jpg" alt="">
                    <div class="down-content">
                      <h4>Genesis Collective Statue</h4>
                      <span class="collection">Items In Collection:<br><strong>380/394</strong></span>
                      <span class="category">Category:<br><strong>Music Art</strong></span>
                      <div class="main-button">
                        <a href="explore.html">Explore Genesis</a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <img src="/assets/images/collection-01.jpg" alt="">
                    <div class="down-content">
                      <h4>Worldwide Artwork Ground</h4>
                      <span class="collection">Items In Collection:<br><strong>426/468</strong></span>
                      <span class="category">Category:<br><strong>Blockchain</strong></span>
                      <div class="main-button">
                        <a href="explore.html">Explore Worldwide</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <!-- 
    <div class="create-nft">
      <div class="container">
        <div class="row">
          
            
        </div>
      </div>
    </div>     -->

    <div class="container mt-4">
      <div class="row justify-content-md-center pt-1 mt-2">
        <div class="col-lg-12">
          <div class="section-heading pb-0 mb-1">
            <div class="line-dec" />
            <h2 class="text-dark">Registration</h2>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <center>
            <div class="main-button">
              <a
                target="_blank noreferrer"
                href="https://docs.google.com/forms/d/e/1FAIpQLSciyEELvLXf-Et7lnHKI-Vx6634J1jjS1h-IONOXpT99VCxXw/viewform?embedded=true"
                >Register Now</a
              >
            </div>
          </center>
          <!-- <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSciyEELvLXf-Et7lnHKI-Vx6634J1jjS1h-IONOXpT99VCxXw/viewform?embedded=true"
            width="100%"
            height="840"
            frameborder="0"
            marginheight="0"
            marginwidth="0">Loading…</iframe
          > -->
        </div>
      </div>
    </div>

    <div class="discover-items pb-1">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12">
            <div class="section-heading pb-1 mb-3 text-center">
              <h2 class="text-dark text-center">Event <em>Partners</em></h2>
            </div>
          </div>
          <div class="col-lg-12 pb-4">
            <div class="container">
              <div class="row justify-content-md-center equal-height-row">
                {#each eventPartners as eventPartner}
                  <div
                    class="col-md-2 mb-2 equal-height-col h-100 align-middle d-flex"
                    style="min-height: 60px;"
                  >
                    <a
                      href={eventPartner.url}
                      style="    flex: 0 0 100%;
                    margin-top: auto;
                    margin-bottom: auto;"
                    >
                      <div
                        class=""
                        style={eventPartner.style +
                          "; padding:6px; min-height:24px;background-image:url('" +
                          eventPartner.src +
                          "');background-size:contain;background-position:center;background-repeat:no-repeat"}
                      >
                        <br />
                        <!-- single client -->
                      </div>
                    </a>
                  </div>
                {/each}
              </div>
              <!-- row -->
            </div>
          </div>
          <hr />
          <div class="col-lg-12">
            <div class="section-heading pb-1 mb-3 text-center">
              <h2 class="text-dark text-center">Media <em>Partners</em></h2>
            </div>
          </div>
          <br />
          <div class="col-lg-12 pb-1">
            <div class="container">
              <div class="row justify-content-md-center">
                {#each internationalMediaPartners as eventPartner}
                  <div class="col-md-2 mb-2 d-flex" style="min-height: 60px;">
                    <a
                      href={eventPartner.url}
                      style="    flex: 0 0 100%;
                    margin-top: auto;
                    margin-bottom: auto;"
                    >
                      <div
                        class=""
                        style={eventPartner.style +
                          "; padding:6px; min-height:24px;background-image:url('" +
                          eventPartner.src +
                          "');background-size:contain;background-position:center;background-repeat:no-repeat"}
                      >
                        <br />
                        <!-- single client -->
                      </div>
                    </a>
                  </div>
                {/each}
              </div>
              <!-- row -->
            </div>
          </div>
          <div class="col-lg-12 pb-3">
            <div class="container">
              <div class="row justify-content-md-center equal-height-row">
                {#each mediaPartners as eventPartner}
                  <div
                    class="col-md-2 mb-2 equal-height-col h-100 align-middle d-flex"
                    style="min-height: 60px;"
                  >
                    <a
                      href={eventPartner.url}
                      style="    flex: 0 0 100%;
                    margin-top: auto;
                    margin-bottom: auto;"
                    >
                      <div
                        class=""
                        style={eventPartner.style +
                          "; padding:6px; min-height:24px;background-image:url('" +
                          eventPartner.src +
                          "');background-size:contain;background-position:center;background-repeat:no-repeat"}
                      >
                        <br />
                        <!-- single client -->
                      </div>
                    </a>
                  </div>
                {/each}
              </div>
              <!-- row -->
            </div>
          </div>
          <hr />
          <div class="col-md-6 col-xs-12 pt-3">
            <div class="section-heading pb-0 mb-2 text-center">
              <h2 class="text-dark text-center"><em>Organized</em> By</h2>
            </div>
            <center
              ><BoopActionHold boopParams={{ scale: 1.2, timing: 100 }}
                ><img
                  loading="lazy"
                  src="/assets/images/logo-lima-umat.png"
                  class="img-fluid"
                  style="max-height: 110px;width:auto"
                  alt=""
                />
              </BoopActionHold></center
            >
          </div>
          <div class="col-md-6 col-xs-12  pt-3">
            <div class="section-heading pb-0 mb-1 text-center">
              <h2 class="text-dark text-center"><em>Hosted</em> By</h2>
            </div>
            <center>
              <BoopActionHold boopParams={{ scale: 1.2, timing: 100 }}
                ><img
                  loading="lazy"
                  src="/assets/images/icon-kpmi.png"
                  class="img-fluid p-3"
                  style="max-height: 120px;width:auto"
                  alt=""
                />
              </BoopActionHold></center
            >
          </div>
        </div>
      </div>
    </div>
  </div></MainLayout
>

<style>
  .logo-nav {
    height: 100%;
    width: auto;
  }
  .nav li {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
  }

  /******************* Timeline Demo - 8 *****************/
  .main-timeline8 {
    overflow: hidden;
    position: relative;
  }
  .main-timeline8:after,
  .main-timeline8:before {
    content: "";
    display: block;
    width: 100%;
    clear: both;
  }
  .main-timeline8:before {
    content: "";
    width: 3px;
    height: 100%;
    background: #d6d5d5;
    position: absolute;
    top: 30px;
    left: 50%;
  }
  .main-timeline8 .timeline {
    width: 50%;
    float: left;
    padding-right: 30px;
    position: relative;
  }
  .main-timeline8 .timeline-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #fe6847;
    position: absolute;
    top: 5.5%;
    right: -17.3;
  }
  .main-timeline8 .year {
    display: block;
    padding: 10px;
    margin: 0;
    font-size: 30px;
    color: #fff;
    border-radius: 0 50px 50px 0;
    background: #fe6847;
    text-align: center;
    position: relative;
  }
  .main-timeline8 .year:before {
    content: "";
    border-top: 33 solid #f59c8b;
    border-left: 33 solid transparent;
    position: absolute;
    bottom: -33;
    left: 0;
  }
  .main-timeline8 .timeline-content {
    padding: 30px 20px;
    margin: 0 43 0 33;
    background: #f2f2f2;
  }
  .main-timeline8 .title {
    font-size: 19px;
    font-weight: 700;
    color: #504f54;
    margin: 0 0 10px;
  }
  .main-timeline8 .description {
    font-size: 14px;
    color: #7d7b7b;
    margin: 0;
  }
  .main-timeline8 .timeline:nth-child(2n) {
    padding: 0 0 0 30px;
  }
  .main-timeline8 .timeline:nth-child(2n) .timeline-icon {
    right: auto;
    left: -14.3;
  }
  .main-timeline8 .timeline:nth-child(2n) .year {
    border-radius: 50px 0 0 50px;
    background: #7eda99;
  }
  .main-timeline8 .timeline:nth-child(2n) .year:before {
    border-left: none;
    border-right: 33 solid transparent;
    left: auto;
    right: 0;
  }
  .main-timeline8 .timeline:nth-child(2n) .timeline-content {
    text-align: right;
    margin: 0 33 0 43;
  }
  .main-timeline8 .timeline:nth-child(2) {
    margin-top: 170px;
  }
  .main-timeline8 .timeline:nth-child(odd) {
    margin: -173 0 0;
  }
  .main-timeline8 .timeline:nth-child(even) {
    margin-bottom: 80px;
  }
  .main-timeline8 .timeline:first-child,
  .main-timeline8 .timeline:last-child:nth-child(even) {
    margin: 0;
  }
  .main-timeline8 .timeline:nth-child(2n) .timeline-icon {
    border-color: #7eda99;
  }
  .main-timeline8 .timeline:nth-child(2n) .year:before {
    border-top-color: #92efad;
  }
  .main-timeline8 .timeline:nth-child(3n) .timeline-icon {
    border-color: #8a5ec1;
  }
  .main-timeline8 .timeline:nth-child(3n) .year {
    background: #8a5ec1;
  }
  .main-timeline8 .timeline:nth-child(3n) .year:before {
    border-top-color: #a381cf;
  }
  .main-timeline8 .timeline:nth-child(4n) .timeline-icon {
    border-color: #f98d9c;
  }
  .main-timeline8 .timeline:nth-child(4n) .year {
    background: #f98d9c;
  }
  .main-timeline8 .timeline:nth-child(4n) .year:before {
    border-top-color: #f2aab3;
  }
  @media only screen and (max-width: 767px) {
    .main-timeline8 {
      overflow: visible;
    }
    .main-timeline8:before {
      top: 0;
      left: 0;
    }
    .main-timeline8 .timeline:nth-child(2),
    .main-timeline8 .timeline:nth-child(even),
    .main-timeline8 .timeline:nth-child(odd) {
      margin: 0;
    }
    .main-timeline8 .timeline {
      width: 100%;
      float: none;
      padding: 0 0 0 30px;
      margin-bottom: 20px !important;
    }
    .main-timeline8 .timeline:last-child {
      margin: 0 !important;
    }
    .main-timeline8 .timeline-icon {
      right: auto;
      left: -14.3;
    }
    .main-timeline8 .year {
      border-radius: 50px 0 0 50px;
    }
    .main-timeline8 .year:before {
      border-left: none;
      border-right: 33 solid transparent;
      left: auto;
      right: 0;
    }
    .main-timeline8 .timeline-content {
      margin: 0 33 0 43;
    }
  }
  .main-banner-title {
    z-index: 2;
    position: absolute;
    top: 45vh;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    color: #fac03a;
    width: 100%;
    text-align: center;
  }
  .main-banner-title span {
    background-color: rgba(80, 79, 84, 0.6);
  }
  .videos img {
    width: 100%;
    height: auto;
  }
  a.video {
    float: left;
    position: relative;
  }
  a.video span {
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("/assets/images/playbtn.png") no-repeat;
    background-position: 50% 50%;
    background-size: 12%;
  }
  @media screen and (max-width: 480px) {
    a.video span {
      background-size: 400%;
    }
  }

  .item-event {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc; /* Border color */
    border-radius: 10px; /* Rounded corners */
    background-color: #f9f9f9; /* Background color */
    padding: 20px; /* Padding inside the box */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for better look */
  }

  .info-item .info-text {
    text-align: center;
  }

  .info-item h4 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333; /* Text color */
  }

  .info-item p {
    margin: 3 0;
    font-size: 1rem;
    color: #666; /* Text color */
  }

  .timeline-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    /* background-color: #f9f9f9; */
  }

  .timeline-box {
    flex: 0 0 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 3px solid #6e63a7;
    padding: 20px;
    background-color: #1b384f;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    border-radius: 0 0 25% 0;
    z-index: 1;
    /* background-image: url(/assets/images/banner-bg.jpg); */
    box-shadow: inset 0 0 0 2000px rgba(20, 52, 82, 0.9);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .timeline-box-2022 {
    background-image: url(/assets/images/about-98.jpg);
  }

  .timeline-box-2023 {
    background-image: url(/assets/images/about-134.jpg);
  }

  .timeline-box-2024 {
    background-image: url(/assets/images/about-50.JPG);
  }

  .timeline-box:hover {
    transform: scale(1.05);
  }

  .box-number {
    font-size: 24px;
    font-weight: bold;
    background: #6e63a7;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 2;
    border: 2px solid #6e63a7;
  }

  .box-content {
    text-align: center;
    z-index: 0; /* Ensures the content stays below the number */
  }

  .year {
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin-bottom: 13;
    background-color: #6e63a7;
    padding: 10px;
    border-bottom-right-radius: 50%;
    margin-bottom: 20px;
    border: 2px solid white;
  }

  .description {
    font-size: 14px;
    color: white;
    margin-bottom: 13;
  }

  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    font-size: 24px;
    color: #fc0000a9;
  }

  @media (max-width: 768px) {
    .timeline-container {
      flex-direction: column;
      align-items: center;
    }

    .arrow-container {
      display: block;
      width: 100%;
      text-align: center;
      margin: 5px 0;
    }

    .arrow {
      transform: rotate(90deg); /* Rotate the arrow to point down */
    }

    .timeline-box {
      margin-bottom: 10px;
      flex: 1 0 auto;
    }
  }
</style>
