<script>
  import Carousel from "svelte-carousel";


  let a = 1;
  let b = 1;
  export let prefix = "hero-";
  export let imageFromAndTo = [0, 1];
  export let autoplayDuration = 10000
</script>

<Carousel
  particlesToShow={a}
  particlesToScroll={b}
  arrows={false}
  autoplay
  autoplayDuration={autoplayDuration}
  dots={false}
>
  {#each { length: imageFromAndTo[1] - imageFromAndTo[0] } as _, i}
    <div class="container-carousel">
        <img class="img-carousel" loading="lazy" src="/assets/images/{prefix}{i + imageFromAndTo[0]}.webp" alt="hero-{prefix}{i + imageFromAndTo[0]}">
    </div>
  {/each}
</Carousel>

<style>
  .container-carousel {
    display: flex;
    min-height: 250px;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    text-align: center;
  }

  .img-carousel {
    max-width: 100%;
    max-height: 90vh;
    /* object-fit: cover; */
  }

</style>
