<script>
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import PageHeader from '../components/page-header/PageHeader.svelte';
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="Services & Partners" />

    <div class="discover-items pb-0">
      <div class="container">
        <div class="row justify-content-md-center  mb-4">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec" />
              <h2 class="text-dark mb-0">Visitor Services</h2>
            </div>
          </div>
          <div class="col-sm-12 col-xs-12 col-lg-6 mb-4">
            Regardless of whether it's tickets, registration, services at the
            trade fair centre or the trade fair program: Are you planning your
            visit to the trade fair and still have questions? The Visitor
            Service team will be happy to help you.
          </div>
          <div class="col-sm-12 col-xs-12 col-lg-6 row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div class="item">
                <div class="row">
                  <div class="col-lg-12">
                    <span
                      >Visitor Service:<br /> <strong>Muthia</strong><br />
                      <i class="fa fa-envelope" />
                      muthia@jakartahalalexpo.com<br />
                      <i class="fa fa-phone" /> +62 813-8068-7118</span
                    >
                  </div>
                  <div class="col-lg-12">
                    <div class="main-button">
                      <a
                        href="https://api.whatsapp.com/send?phone=6281380687118"
                        ><i class="fa fa-phone mr-2" /> Contact Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6  col-md-12 col-sm-12 col-xs-12">
              <div class="item">
                <div class="row">
                  <div class="col-lg-12">
                    <span
                      >Visitor Service:<br /> <strong>Annisa</strong><br />
                      <i class="fa fa-envelope" />
                      annisa@jakartahalalexpo.com<br />
                      <i class="fa fa-phone" /> +62 822-8668-3970
                    </span>
                  </div>
                  <div class="col-lg-12">
                    <div class="main-button">
                      <a
                        href="https://api.whatsapp.com/send?phone=6282286683970"
                        ><i class="fa fa-phone mr-2" /> Contact Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</MainLayout>
