<script>
  import { onMount } from 'svelte';
    let url = ``;
    onMount(() => {
      url = window.location.pathname
  });
</script>
 <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <a href="#" class="logo">
              <img class="logo-nav" src="/assets/images/logo-white.png" alt="" />
              <!-- <img class="logo-nav" src="/assets/images/logo-white.png" alt="" />               -->
            </a>
            <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** -->
            <ul class="nav" style="vertical-align: middle;margin-top:auto;margin-bottom:auto">
              <li><a href="/" class:active="{url === "/"}">Home</a></li>
              <li><a href="/about" class:active="{url.includes("/about")}">About</a></li>
              <li><a href="/visit" class:active="{url.includes("/visit")}">Visit</a></li>
              <li><a href="/exhibit" class:active="{url.includes("/exhibit")}">Exhibit</a></li>
              <li><a href="/event" class:active="{url.includes("/event")}">Event</a></li>
              <li><a href="/media" class:active="{url.includes("/media")}">Media & Press</a></li>
              <li><a href="/partners" class:active="{url.includes("/partners")}">Services Partner</a></li>
              <li><a href="/contact" class:active="{url.includes("/contact")}">Contact</a></li>
            </ul>
            <a class="menu-trigger">
              <span>Menu</span>
            </a>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
<style>

</style>
