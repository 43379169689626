<script>
  export let images ;
  let index = 0;
  let i = 0;
  let flip = true;

  let firstIndex = 0;
  let secondIndex = 1;
  let showFirst = true;
  setInterval(() => {
    flip = !flip;
    index = index >= images.length-1 ? 0 : index + 1;

    showFirst = !showFirst;
    setTimeout(() => {
      if (showFirst) {
        secondIndex = (secondIndex + 1) % images.length;
      } else {
        firstIndex = (firstIndex + 1) % images.length;
      }
    }, 500);
  }, 4000);
</script>

<img class="img-fluid flip-box-front" src={images[index]} alt="test1" />

<!-- 
<div>
  <Motion
    animate={{ rotateY: flip ? 0 : 180 }}
    transition={{ duration: 1 }}
    let:motion
  >
    <img
      class:d-none={!flip}
      class="img-fluid flip-box-front"
      src={images[index]}
      alt="test1"
      use:motion
    />
  </Motion>
  <Motion
    initial={{ rotateY: 180 }}
    animate={{ rotateY: flip ? 180 : 0 }}
    transition={{ duration: 1 }}
    let:motion
  >
    <img
    class:d-none={flip}
      class="img-fluid flip-box-front"
      style="rotateY:180"
      src={images[index]}
      alt="test1"
      use:motion
    />
  </Motion>
</div> -->
<style>
  /* Position the front and back side */
  .flip-box-front,
  .flip-box-back {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  /* Style the front side */
  .flip-box-front {
    background-color: #ddd;
    color: black;
  }

  @keyframes revealTextSlowly {
    to {
      color: white;
    }
  }

  .conceal-answer {
    animation: revealTextSlowly 0.3s forwards;
  }

  /* Style the back side */
  .flip-box-back {
    background-color: #61592f;
    color: #61592f;
    /* 		color: white; */
    font-size: 1.3rem;
    transform: rotateY(180deg);
  }

  .visible {
    opacity: 1;
  }
</style>
