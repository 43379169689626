<script>
  export let title;
</script>

<div class="page-heading space-title">
  <!-- <img src="/assets/images/logo-jhec-colored.png" alt="Logo" class="logo-title"> -->
  <div class="container">
    <div class="row">
      <!-- <div class="social-icons">
        <a href="https://web.facebook.com/halalexpoindonesia2020/?_rdc=1&_rdr" target="_blank noreferrer" class="me-2 animate__animated animate__infinite animate__swing"><i class="fab fa-facebook"></i></a>
        <a href="https://www.youtube.com/watch?v=1rC0XnCRyuo" target="_blank noreferrer" class="me-2 animate__animated animate__infinite animate__swing"><i class="fab fa-youtube"></i></a>
        <a href="https://twitter.com/halalexpoidn" target="_blank noreferrer" class="me-2 animate__animated animate__infinite animate__swing"><i class="fab fa-twitter"></i></a>
        <a href="https://www.instagram.com/halalexpoindonesia/?hl=en" target="_blank noreferrer" class="me-2 animate__animated animate__infinite animate__swing"><i class="fab fa-instagram"></i></a>
      </div> -->
      <div class="page-title animate__animated animate__fadeInUp animate__slow">
        <h3 class="pt-2 animate__animated animate__infinite animate__pulse">{title}</h3>
      </div>
    </div>
  </div>
</div>

