<script>
  import Header from "components/header/Header.svelte";

  import { onMount } from "svelte";

  let ready = false;
  // let y = 0;
  onMount(() => {
    ready = true;
  //   let checker = setTimeout(() => (y = 0), 500);
  });

  import LibLoader from "./LibLoader.svelte";

  function onLoaded() {}
</script>

<svelte:head />
<!-- <svelte:window bind:scrollY={y} /> -->

<!-- ***** Preloader Start ***** -->
<div id="js-preloader" class="js-preloader">
  <div class="preloader-inner">
    <span class="dot" />
    <div class="dots">
      <span />
      <span />
      <span />
    </div>
  </div>
</div>
<!-- ***** Preloader End ***** -->

<!-- ***** Header Area Start ***** -->
<Header />
{#if ready}
  <!-- <div
    transition:scale={{
      duration: 500,
      delay: 500,
      opacity: 0.5,
      start: 0.5,
      easing: quintOut,
    }}
  > -->
    <slot name="content" />
  <!-- </div> -->
{/if}

<section style="background-color:#6e63a7" class="text-light">
  <div class="container pt-4 py-2">
    <div class="row">
      <div class="col-lg-3">
        <div class="lc-block mb-2">
          <img
            class="img-fluid"
            alt="logo"
            src="/assets/images/logo-text-white.png"
            style="height:auto;width:40%"
          />
        </div>
        <div class="lc-block small">
          <div editable="rich">
            <p style="font-size: x-small;line-height:normal">
              PT. Lima Event Indonesia<br />
              Graha Inti Fauzi, Jl. Buncit Raya No.22 9th Floor, RT.2/RW.7, Pejaten
              Bar., Ps. Minggu,<br />
              Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12510
            </p>
          </div>
        </div>
        <!-- /lc-block -->
        <div class="lc-block py-2">
          <a
            class="text-decoration-none"
            href="https://web.facebook.com/halalexpoindonesia2020/?_rdc=1&_rdr"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              width="1em"
              height="1em"
              lc-helper="svg-icon"
              fill="var(--bs-light)"
            >
              <path
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
              />
            </svg>
          </a>
          <a
            class="text-decoration-none"
            href="https://twitter.com/halalexpoidn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="1em"
              height="1em"
              lc-helper="svg-icon"
              fill="var(--bs-light)"
            >
              <path
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>
          </a>
          <a
            class="text-decoration-none"
            href="https://www.instagram.com/halalexpoindonesia/?hl=en"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="1em"
              height="1em"
              lc-helper="svg-icon"
              fill="var(--bs-light)"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
          <a
            class="text-decoration-none"
            href="https://www.youtube.com/watch?v=1rC0XnCRyuo"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              width="1em"
              height="1em"
              lc-helper="svg-icon"
              fill="var(--bs-light)"
            >
              <path
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="col-lg-8 offset-lg-1 row">
        <div class="col-lg-12">
          <div class="lc-block mb-3">
            <div editable="rich">
              <h6 style="font-size: small;">Sales Contact</h6>
            </div>
          </div>
          <!-- /lc-block -->
          <div class="lc-block small">
            <div editable="rich">
              <div class="row">
                <div class="col-md-4 mb-2">
                  <strong style="font-size: smaller;"> Fachrul </strong>
<br/>
                  <a
                    href="https://wa.me/6289519582541"
                    style="width:100%; font-size:smaller"
                    class="d-inline-block text-white"><i class="fa fa-phone"></i> +62 895-1958-2541</a
                  ><br />
                  <a
                    href="mailto:fachrul@jakartahalalexpo.com"
                    style="width:100%;overflow:hidden;font-size:smaller"
                    class=" text-white d-inline-block "
                    ><i class="fa fa-envelope"></i> fachrul@jakartahalalexpo.com</a
                  >
                </div>

                <div class="col-md-4 mb-2">
                  <strong style="font-size: smaller;"> Muthia </strong>
<br/>
                  <a
                    href="https://wa.me/6281380687118"
                    style="width:100%; font-size:smaller"
                    class="d-inline-block text-white"><i class="fa fa-phone"></i> +62 813-8068-7118</a
                  ><br />
                  <a
                    href="mailto:muthia@jakartahalalexpo.com"
                    style="width:100%;overflow:hidden;font-size:smaller"
                    class=" text-white d-inline-block "
                    ><i class="fa fa-envelope"></i> muthia@jakartahalalexpo.com</a
                  >
                </div>

                <div class="col-md-4 mb-2">
                  <strong style="font-size: smaller;"> Annisa </strong>
                  <br/>
                  <a
                    href="https://wa.me/6282286683970"
                    style="width:100%; font-size:smaller"
                    class="d-inline-block text-white"><i class="fa fa-phone"></i> +62 822-8668-3970</a
                  ><br />
                  <a
                    href="mailto:annisa@jakartahalalexpo.com"
                    style="width:100%;overflow:hidden;font-size:smaller"
                    class=" text-white d-inline-block "
                    ><i class="fa fa-envelope"></i> annisa@jakartahalalexpo.com</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /lc-block -->
        </div>
        <div class="col-lg-12">
          <div class="lc-block mb-3">
            <div editable="rich">
              <h6 style="font-size: small;">Partnership Contact</h6>
            </div>
          </div>
          <!-- /lc-block -->
          <div class="lc-block small">
            <div editable="rich">
              <div class="row">

                <div class="col-md-4 mb-2">
                  <strong style="font-size: smaller;"> Andina </strong>
                  <br/>
                  <a
                    href="https://wa.me/6289694635859"
                    style="width:100%; font-size:smaller"
                    class="d-inline-block text-white"><i class="fa fa-phone"></i> +62 896-9463-5859</a
                  ><br />
                  <a
                    href="mailto:andina@jakartahalalexpo.com"
                    style="width:100%;overflow:hidden;font-size:smaller"
                    class=" text-white d-inline-block "
                    ><i class="fa fa-envelope"></i> andina@jakartahalalexpo.com</a
                  >
                </div>

                <div class="col-md-4 mb-2">
                  <strong style="font-size: smaller;"> Dania </strong>
                  <br/>
                  <a
                    href="https://wa.me/6287705594556"
                    style="width:100%; font-size:smaller"
                    class="d-inline-block text-white"><i class="fa fa-phone"></i> +62 877-0559-4556</a
                  ><br />
                  <a
                    href="mailto:dania@jakartahalalexpo.com"
                    style="width:100%;overflow:hidden;font-size:smaller"
                    class=" text-white d-inline-block "
                    ><i class="fa fa-envelope"></i> dania@jakartahalalexpo.com</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /lc-block -->
        </div>
      </div>
    </div>
  </div>
  <div class="pt-1 pb-4 container">
    <div class="row">
      <div class="col-12 small">
        <div class="lc-block">
          <div editable="rich">
            <p class="text-center" style="font-size: smaller;">
              Copyright © 2024 JAKARTA HALAL EXPO AND CONFERENCE Lima Events.
              All rights reserved.
            </p>
          </div>
        </div>
        <!-- /lc-block -->
      </div>
    </div>
  </div>
</section>

<LibLoader url="/assets/js/all.js" on:loaded={onLoaded} />
<!-- 
<LibLoader url="/assets/vendor/jquery/jquery.min.js"
on:loaded="{onLoaded}" /> -->
<!-- <LibLoader url="/assets/vendor/bootstrap/js/bootstrap.min.js"
on:loaded="{onLoaded}" /> -->

<!-- <LibLoader url="/assets/js/isotope.min.js"
on:loaded="{onLoaded}" /> -->
<!-- 
<LibLoader url="/assets/js/owl-carousel.js"
on:loaded="{onLoaded}" />

<LibLoader url="/assets/js/tabs.js"
on:loaded="{onLoaded}" />

<LibLoader url="/assets/js/popup.js"
on:loaded="{onLoaded}" />
<LibLoader url="/assets/js/custom.js"
on:loaded="{onLoaded}" /> -->
