<script>
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import PageHeader from '../components/page-header/PageHeader.svelte';
  let shouldAttends = [
    { title: "Halal industry professionals.", icon: "fa-briefcase" },
    {
      title: "Business owners and entrepreneurs in the halal sector.",
      icon: "fa-building",
    },
    {
      title: "Manufacturers and suppliers of halal-certified products.",
      icon: "fa-industry",
    },
    {
      title: "Halal certification agencies and consultants.",
      icon: "fa-user-tie",
    },
    {
      title:
        "Government officials and policymakers involved in halal regulation and promotion.",
      icon: "fa-landmark",
    },
    {
      title:
        "Researchers, academics, and students interested in halal studies. ",
      icon: "fa-graduation-cap",
    },
  ];

  let attends = [
    {
      text: "Networking Opportunities: Connect with industry leaders, experts, and potential partners to expand your network and explore collaboration opportunities.",
      icon:"fa-network-wired",
    },
    {
      text: "Market Insights: Gain valuable insights into the latest trends, regulations, and market dynamics shaping the halal industry in Indonesia and beyond.",
      icon:"fa-chart-line",
    },
    {
      text: "Showcase Your Products: Exhibit your products and services to a targeted audience of buyers and decision-makers looking for halal-certified offerings.",
      icon:"fa-tags",
    },
    {
      text: "Educational Sessions: Attend informative workshops, panel discussions, and seminars conducted by industry experts to enhance your knowledge and skills.",
      icon:"fa-graduation-cap",
    },
    {
      text: "Business and Investment Opportunities: Discover new business opportunities, explore potential partnerships, and expand your market reach in the thriving halal industry.",
      icon:"fa-briefcase",
    },
  ]
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="Visit" />

    <div class="item-details-page visit-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading mb-2">
              <div class="line-dec" />
              <h2 class="text-dark">Who Should <em>Attend?</em></h2>
            </div>
          </div>
          {#each shouldAttends as shouldAttend, i}
            <div
              class="col-md-4 col-sm-6 col-xs-12 row justify-content-center mt-4"
            >
              <div class="col-md-12 text-center">
                <i class="fa {shouldAttend.icon} fa-3x" />
              </div>
              <div class="col-md-12 text-center">
                <h6 class="text-dark text-center">{shouldAttend.title}</h6>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
    
    <div class="categories-collections">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading mb-4">
              <div class="line-dec" />
              <h2>Why <em>Attend?</em></h2>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center mt-4">
              {#each attends as attend, i}
                <div class="col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center mb-4">
                  <div class={"text-center p-4 rounded-corner " + (i % 2 === 0 ? "border-warning bg-warning" : "border-secondary bg-secondary")}>
                    <div class="mb-3">
                      <i class={"fas "+(attend.icon)+" fa-3x " + (i % 2 === 0 ? "text-dark" : "text-white")}></i>
                    </div>
                    <h6 class={"text-justify " + (i % 2 === 0 ? "text-dark" : "text-white")}>
                      {attend.text}
                    </h6>
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="discover-items pb-0 mb-5">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 col-xs-12 col-lg-6 col-md-6 mb-4 border-right">
            <div class="section-heading mb-4">
              <div class="line-dec" />
              <h2 class="text-dark">Opening Hours</h2>
            </div>
            <div class="text-dark">
              <ul style="line-height: 24px;">
                <li>
                  <strong
                    ><span class="fa fa-handshake" /> TRADE DAYS : 30 August 2024</strong
                  >
                </li>
                <li>OPENING HOURS : 10.00 a.m. - 06.00 p.m.</li>
                <li><br /></li>
                <li>
                  <strong
                    ><span class="fa fa-users" /> PUBLIC DAYS : 31 Aug - 01 Sept
                    2024</strong
                  >
                </li>
                <li>OPENING HOURS : 10.00 a.m. - 09.00 p.m.</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 col-xs-12 col-lg-6 col-md-6 border-left">
            <div class="pl-4 section-heading mb-4">
              <div class="line-dec" />
              <h2 class="text-dark">Admission</h2>
            </div>
            <div class="pl-4 text-dark row">
              <strong>Office Attire / Business Casual. No slippers / sandals, singlets, shorts are allowed.</strong>
              <div class="col-md-12">
                <img
                  src="/assets/images/icon-admission.png"
                  alt="Admission Icon"
                  class="img-responsive"
                />
              </div>
              <i>*Only Applicable for TRADE DAYS</i>
              <i>**The Organiser reserves the right to refuse admission, without prejudice, to visitors who are deemed to be inappropriately dressed.</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</MainLayout>

<style>
  .visit-section div div div i.fa {
    color: red;
    text-align: center;
    font-size: 48px;
    padding: 16px;
  }
  .visit-section div div div:nth-child(2) i.fa {
    color: #2596be !important;
  }
  .visit-section div div div:nth-child(3) i.fa {
    color: #3c445e !important;
  }
  .visit-section div div div:nth-child(4) i.fa {
    color: #f8c140 !important;
  }
  .visit-section div div div:nth-child(5) i.fa {
    color: #7ec6ce !important;
  }
  .visit-section div div div:nth-child(6) i.fa {
    color: #526371 !important;
  }
  .visit-section div div div:nth-child(7) i.fa {
    color: #2268a2 !important;
  }
</style>
