<script>
  import ContactSection from "../components/chunks/ContactSection.svelte";
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import PageHeader from '../components/page-header/PageHeader.svelte';
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="Contact" />

    <div class="discover-items pb-0">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec" />
              <h2 class="text-dark">Address</h2>
            </div>
          </div>
          <div class="col-sm-12 col-xs-12 col-lg-6">
            <strong>PT. Lima Event Indonesia</strong><br/>Graha Inti Fauzi, Jl. Buncit Raya No.22 9th
            Floor, RT.2/RW.7, <br/>Pejaten Bar., Ps. Minggu, <br/>Kota Jakarta Selatan,
            Daerah Khusus Ibukota Jakarta 12510
            <br/><br/>
            <a href="https://www.google.com/maps/place/LIMA+EVENT+INDONESIA/@-6.2757015,106.8295198,15z/data=!4m15!1m8!3m7!1s0x2e69f37888a8a21d:0x10d0c647a2460da2!2sLIMA+EVENT+INDONESIA!8m2!3d-6.275844!4d106.8295557!10e5!16s%2Fg%2F11fpq31x6q!3m5!1s0x2e69f37888a8a21d:0x10d0c647a2460da2!8m2!3d-6.275844!4d106.8295557!16s%2Fg%2F11fpq31x6q?entry=ttu"><img src="/assets/images/icon-google-maps.png" style="height: 48px; width:auto" class="mr-1"/> Open in Google Maps</a>
            <br/><br/><a href="https://www.waze.com/en/live-map/directions?latlng=-6.2757015%2C106.8295198"><img src="/assets/images/icon-waze.png" class="mr-1 d-inline" style="height: 48px; width:auto"/> Open in Waze</a>            
          </div>
          <div class="col-sm-12 col-xs-12 col-lg-6">
            <div style="width: 100%">
              <iframe
                width="100%"
                height="200"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Graha%20Inti%20Fauzi,%20%20Jl.%20Buncit%20Raya%20No.22%209th%20Floor,%20RT.2/RW.7,%20Pejaten%20Bar.,%20Ps.%20Minggu,%20%20Kota%20Jakarta%20Selatan,%20Daerah%20Khusus%20Ibukota%20Jakarta%2012510et,%20Dublin,%20Ireland+(Lima%20Event%20-%20JHEC%20Office)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ><a href="https://www.gps.ie/">gps systems</a></iframe
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ContactSection withDetail="true"/>
  </div>
</MainLayout>
