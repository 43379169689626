<script>
  export let withDetail;
  if (typeof withDetail === "undefined") {
    withDetail = false;
  }
  export let id = "contact-section";
</script>

<div class="discover-items" {id}>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-12">
        <div class="section-heading mb-2">
          <div class="line-dec" />
          <h2 class="text-dark">Contact <em>Us</em>.</h2>
        </div>
      </div>
      {#if withDetail}
        <div class="col-lg-12 p-2 text-center bg-primary text-white mb-4 border border-primary">
          <strong>Sales</strong><br />More Information, Contact Sales &
          Sponsorship
        </div>
      {/if}
      <div class="col-lg-4">
        <div class="bg-secondary text-white border rounded border-secondary">
          <div class="row m-0">
            <div class="col-lg-8 mt-auto mb-auto">
              <span><strong>Fachrul</strong></span>
            </div>
            <div class="col-lg-4 m-0 p-0 text-right">
              <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=6289519582541">
                <i class="fa fa-phone mr-1"></i>
              </a>
              <a class="btn btn-warning" href="mailto:fachrul@jakartahalalexpo.com">
                <i class="fa fa-envelope text-white mr-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-4">
        <div class="bg-secondary text-white border rounded border-secondary">
          <div class="row m-0">
            <div class="col-lg-8 mt-auto mb-auto">
              <span><strong>Muthia</strong></span>
            </div>
            <div class="col-lg-4 m-0 p-0 text-right">
              <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=6281380687118">
                <i class="fa fa-phone mr-1"></i>
              </a>
              <a class="btn btn-warning" href="mailto:muthia@jakartahalalexpo.com">
                <i class="fa fa-envelope text-white mr-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="bg-secondary text-white border rounded border-secondary">
          <div class="row m-0">
            <div class="col-lg-8 mt-auto mb-auto">
              <span><strong>Annisa</strong></span>
            </div>
            <div class="col-lg-4 m-0 p-0  text-right">
                <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=6282286683970"
                  ><i class="fa fa-phone mr-1" /></a
                >
                <a class="btn btn-warning" href="https://api.whatsapp.com/send?phone=6282286683970"
                  ><i class="fa fa-envelope text-white mr-1" /></a
                >
            </div>
          </div>
        </div>
      </div>

      {#if withDetail}
        <div class="col-lg-12 text-center bg-info text-white mb-4 border border-info p-2 mt-4">
          <strong>Join Promotions</strong><br />More Information, Contact Media
          and Partnership
        </div>
      {/if}
      <div class="col-lg-4">
        <div class="bg-secondary text-white border rounded border-secondary">
          <div class="row m-0">
            <div class="col-lg-8 mt-auto mb-auto">
              <span><strong>Andina</strong></span>
            </div>
            <div class="col-lg-4 m-0 p-0 text-right">
              <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=6289694635859">
                <i class="fa fa-phone mr-1"></i>
              </a>
              <a class="btn btn-warning" href="mailto:andina@jakartahalalexpo.com">
                <i class="fa fa-envelope text-white mr-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-4">
        <div class="bg-secondary text-white border rounded border-secondary">
          <div class="row m-0">
            <div class="col-lg-8 mt-auto mb-auto">
              <span><strong>Dania</strong></span>
            </div>
            <div class="col-lg-4 m-0 p-0 text-right">
              <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=6287705594556">
                <i class="fa fa-phone mr-1"></i>
              </a>
              <a class="btn btn-warning" href="mailto:dania@jakartahalalexpo.com">
                <i class="fa fa-envelope text-white mr-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
