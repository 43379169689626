<script>
    import MainLayout from "../../../layouts/MainLayout.svelte";
  import PageHeader from '../../../components/page-header/PageHeader.svelte';

    export let slug =
        "jakarta-halal-expo-and-conference-siap-bergabung-di-muslim-lifefest-agustus-mendatang";
</script>

<MainLayout>
    <div slot="content">
        <PageHeader title="Media & Press" />
        <div class="container mt-4">
            <div class="row">
                <div class="col-md-12 mb-4">
                    <h3 class="text-center text-dark mt-4">
                        Muslim Life Fair 2024 Suguhkan <i>One Stop Solution</i>
                        <br>
                        Keluarga Muslim Sambut Ramadan 1445 H
                    </h3>
                    <img class="mt-4" src="/assets/images/press-release-1.jpg" alt="">
                </div>
                <div class="col-md-12 blog-main mt-4">
                    <div class="blog-post">
                        <!-- <h2 class="blog-post-title text-dark">Jakarta Halal Expo & Conference Siap Bergabung di Muslim Lifefest Agustus Mendatang</h2> -->
                        <!-- <p class="text-justify blog-post-meta  text-dark">
                            Kamis, 22 Februari 2024 - 06:37 WIB
                        </p> -->
                        <!-- <br/> -->
                        <p class="text-justify mb-4 text-dark">
                            <strong>Jakarta, 18 Februari 2024</strong> - 
                            Pengunjung keluarga yang mendominasi pameran Muslim Life Fair dalam dua tahun terakhir mendorong Lima Event bersama Komunitas Pengusaha Muslim Indonesia (KPMI) menggelar event  bertajuk “Pesta Keluarga Muslim” yang siap diselenggarakan di Hall B Jakarta International Expo (JIEXPO), Kemayoran pada 8-10 Maret 2024. 
                            <br>
                            Menempati area seluas 4.000 m2, sebanyak 135 exhibitor dari Jabodetabek dan daerah lainnya siap menampilkan berbagai kebutuhan produk halal dan islami, mulai dari fashion & accessories, pendidikan islami, halal travel & umroh, halal herbal, wedding syar’i, kids & baby product, islamic book & publisher, halal cosmetic, multi product, otomotif, hingga kuliner halal aman & sehat (KHAS).
                            <br>
                            Pameran Muslim Life Fair tahun ini menandai pelaksanaannya yang ketiga sejak pertama kali digelar pada 2022 dengan jumlah pengunjung lebih dari 20 ribu orang setiap tahunnya. Sebagai pameran gaya hidup syariah dan halal terbesar di Indonesia, Muslim Life Fair 2024 menjadi one-stop solution bagi keluarga muslim dalam memenuhi kebutuhan sekaligus mengupgrade ilmu dalam menyambut bulan suci Ramadan 1445 Hijriah.
                            <br>
                            Bulan Ramadhan memang menjadi momen spesial kaum muslimin berlomba-lomba dalam kebaikan dan berbagi kebahagiaan. Bisa dipahami jika  antusiasme menyambut bulan suci, kerap ditandai dengan skalasi belanja masyarakat yang cenderung mengalami peningkatan. Berdasarkan data yang dirilis oleh Mandiri Spending Index, indeks nilai belanja hingga April 2023 yang bertepatan dengan bulan Ramadan 2023 mencatat angka sebesar 156,7. Jumlah ini mengalami kenaikan dari 136,4 pada akhir Maret 2023. 
                            <br>
                            Melihat potensi tersebut, General Manager Lima Event, Ilham Pradipta optimis kenaikan belanja masyarakat juga dapat terjadi pada Ramadan 2024. “Menjelang dan memasuki bulan suci Ramadan, kebutuhan keluarga muslim akan meningkat. Hadirnya Muslim Life Fair diharapkan  menjadi momentum bagi keluarga muslim memenuhi kebutuhan puasa dan lebaran. Jadi, selama bulan suci, masyarakat muslim bisa fokus beribadah,” ujar Ilham Pradipta.
                            <br>
                            Untuk menarik perhatian pengunjung, pihaknya akan memberikan berbagai penawaran menarik di Muslim Life Fair 2024 seperti bazaar murah, flash sale, hingga tebus murah Rp 5.000 untuk beragam produk.
                            <br>
                            Selain bazaar produk muslim, Muslim Life Fair kali ini juga semakin semarak dengan berbagai rangkaian acara menarik seperti bincang santai mengenai parenting bersama para ahli di bidangnya. Ada juga bincang keluarga Islami bersama para asatidz seperti Ustadz Khalid Basalamah, Ustadz Syafiq Riza Basalamah, dan Ustadz Nuzul Dzikri. 
                            <br>
                            Selain itu, hadir kembali program existing Serlok Kuliner- bersama dengan Uki Kautsar dan Reda Samudera yang akan menyuguhkan obrolan hangat seputar bisnis kuliner sambil menyantap hidangan kuliner halal, aman dan lezat di di area multi produk.
                            <br>
                            Bagi keluarga muslim yang membawa anak tak perlu khawatir karena Muslim Life Fair juga menyediakan berbagai wahana menghibur dan edukatif. Salah satunya adalah playground yang menyajikan sejumlah kelas eksperimen seperti volcano eruption, painting gypsum dan surprise fizzy egg. 
                            <br>
                            Untuk meningkatkan kemampuan anak demi membangun generasi masa depan Islam yang lebih baik, Muslim Life Fair 2024 juga menyelenggarakan berbagai kompetisi dengan hadiah menarik. Salah satunya adalah lomba MHQ (Musabaqoh Hifdzil Quran). Bagi penggemar olahraga ekstrim, ada pula kegiatan wall climbing yang amat sayang untuk dilewatkan.
                            <br>
                            Dengan hadirnya beragam kegiatan, Ilham menyatakan optimismenya akan pelaksanaan Muslim Life Fair yang akan mengalami peningkatan jumlah pengunjung. “Sebagai one stop solution keluarga muslim mengupgrade diri dan memenuhi kebutuhan menyambut bulan Ramadan, kami optimis jumlah pengunjung Muslim Life Fair 2024 mengalami peningkatan dibandingkan sebelumnya. Insya Allah sebanyak 30.000 orang akan memenuhi gelaran ini.”
                            <br>
                            Muslim Life Fair 2024 juga menjadi rangkaian dari Road To Muslim Life Fest yang akan diselenggarakan di ICE BSD Tangerang (30 Agustus - 1 September 2024) kemudian dilanjutkan dengan acara penutup berupa Pesta Anak Muslim (Jakarta Convention Centre, 27-29 Desember 2024).
                            <br>
                        </p>
                        <p class="text-justify text-dark">
                            <strong>Tentang Penyelenggara</strong>
                        </p>
                        <p class="text-justify text-dark">
                            <strong>PT Lima Event Indonesia (Lima Events)</strong> adalah perusahaan Professional Islamic Exhibition Organizer di Indonesia yang berdiri sejak tahun 2019. Bagi kami, menjadi exhibition organizer bukanlah sekedar berbisnis, tetapi merupakan ikhtiar untuk terus menebar pesan-pesan Islami dalam setiap langkah yang kami jalani. Bervisi pada komitmen mengedepankan nilai- nilai Islami pada bisnis yang dijalani, Lima Events membangun sinergi untuk memajukan industri Islami di Indonesia. 
                            <br>
                            Komunitas Pengusaha Muslim Indonesia (KPMI) didirikan pada tahun 2010 di Bogor oleh beberapa asatidzah dan pengusaha muslim yang memiliki keinginan kuat menyebarluaskan ilmu tentang bagaimana pengusaha muslim melaksanakan dan mengelola usahanya sesuai
                            َََّهَْه َََّّهََََََّْ ر ِضَ اللَََعنهَ dan para sahabat صلََّاللَََعليهَ وسلمَ dengan contoh yang diberikan oleh Rasulullah serta generasi Islam terdahulu agar selamat dunia dan akhirat. 
                            <br>
                            KPMI berasaskan Pancasila dan Undang-Undang Dasar 1945 dan dalam menjalankan program serta kegiatannya selalu berpedoman dan berlandaskan pada Akidah Islam yang lurus dan menjalankan ketentuan-ketentuan sesuai Syari’at Islam, berdasarkan Al Quran dan Hadits yang shahih sesuai dengan pemahaman salafush shalih (ulama-ulama shaleh terdahulu). 
                            <br>
                            Saat ini anggota KPMI tersebar di 45 Korwil, 42 Korwil Kabupaten/Kota di seluruh Indonesia dan 3 Korwil di luar negeri. Anggota KPMI yang terdaftar berjumlah lebih dari 48.000 orang baik yang sudah jadi pengusaha maupun calon pengusaha. 
                        </p>
                    </div>
                    <!-- /.blog-post -->
                </div>
            </div>
        </div>
    </div>
</MainLayout>
