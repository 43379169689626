<script>
  import FlipImage from "../components/animation/flipcard/FlipImage.svelte";
  import ImageCrossfade from "../components/carousel/ImageCrossfade.svelte";
  import MainLayout from "../layouts/MainLayout.svelte";
  import { _ } from "svelte-i18n";
  import MediaImages from "../components/carousel/MediaImages.svelte";
  import BoopAction from "../components/animation/boop/BoopAction.svelte";
  import BoopActionHold from "../components/animation/boop/BoopActionHold.svelte";
  import PageHeader from "../components/page-header/PageHeader.svelte";

  let galleries = [
    "/assets/images/about-1.jpg",
    "/assets/images/about-2.jpg",
    "/assets/images/about-3.jpg",
    "/assets/images/about-4.jpg",
    "/assets/images/about-5.jpg",
    "/assets/images/about-6.jpg",
    "/assets/images/about-7.jpg",
    "/assets/images/about-8.jpg",
  ];

  let testimonies = [
    {
      text: "Alhamdulillah thank you very much Muslim Life Trade for providing an opportunity to connect with Muslim entrepreneurs and participate in 2 business matchmaking meetings.",
      author: "Ali Pahlevi Rad, representing PT. Indonesia Iran Trade Center",
    },
    {
      text: "Appreciation for participating in the event and expressed hope for success for Muslims worldwide.",
      author: "Eyad El-Bqdghadady from Gold Pyramid",
    },
    {
      text: "Thank you for the opportunity to participate in Muslim Life Trade in Jakarta and praised the program's excellence.",
      author: "Dr. Muhammad Fajrin Abdurrahman of Fastlane International",
    },
    {
      text: "Presenting the Sri Lanka booth at Muslim Life Trade for the first time, emphasizing the importance of diverse platforms for participation",
      author:
        "Muhammed Shemida Rasmin, Senior Manager at PT. EFL Global Indonesia",
    },
    {
      text: "Alhamdulillah, we are glad for joining Muslim Life Trade, highlighting their premium products such as date honey and olive oil and thanking the organization.",
      author: "Ali, an exhibitor from Tunisia",
    },
  ];
</script>

<MainLayout>
  <div slot="content">
    <PageHeader title="About JHEC" />

    <div class="item-details-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading pb-2">
              <div class="line-dec" />
              <h2 class="text-dark">Background & <em>Preview</em> 2024.</h2>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 mb-4">
            <center
              class="animate__animated animate__backInLeft animate__delay-1s animate__slow"
            >
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rYAVEkMwdp0?si=bWtIQmaUeuKF09zm"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              />
            </center>
          </div>
          <div class="col-lg-6 col-xs-12 mb-4">
            <center
              class="animate__animated animate__backInRight animate__delay-1s animate__slow"
            >
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/EdSUxoBG96Q"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              />
            </center>
          </div>

          <div class="col-lg-12 mt-2 align-self-center">
            <!-- <h4 class="text-dark">Dolores Haze Westworld Eye</h4> -->
            <p
              class="text-dark animate__animated animate__fadeInUp animate__delay-2s"
            >
              After successfully organizing previous exhibitions in 2022 and
              2023 through collaboration with Muslim Life Trade and Halal Expo
              Indonesia, The Indonesian Muslim Entrepreneurs Community (KPMI)
              and Lima Events are proud to present the transformation of a new
              exhibition named Jakarta Halal Expo & Conference.
            </p>
            <p
              class="text-dark animate__animated animate__fadeInUp animate__delay-3s"
            >
              The Jakarta Halal Expo & Conference is a new, more innovative and
              inclusive platform designed to drive the progress of the Sharia
              Economy. With the theme <strong
                >"Uniting the World Through Halal Excellence"</strong
              > it will gather key stakeholders from around the globe to showcase
              the latest halal products and services, exchange insights on industry
              trends, and explore new business opportunities.
            </p>
          </div>
          <div class="col-lg-4 mb-5 mt-2">
            <div class="left-image">
              <BoopActionHold boopParams={{ scale: 1.2, timing: 200 }}>
                <img
                  src="/assets/images/about-2022.png"
                  alt=""
                  class="animate__animated animate__fadeInUp animate__delay-1s animate__slow"
                  style="border-radius: 20px;"
                />
              </BoopActionHold>
            </div>
          </div>
          <div class="col-lg-4  mb-5 mt-2">
            <div class="left-image">
              <BoopActionHold boopParams={{ scale: 1.2, timing: 200 }}>
                <img
                  src="/assets/images/about-2023.png"
                  alt=""
                  class="animate__animated animate__fadeInUp animate__delay-1s animate__slow"
                  style="border-radius: 20px;"
                />
              </BoopActionHold>
            </div>
          </div>
          <div class="col-lg-4  mb-5 mt-2">
            <div class="left-image">
              <BoopActionHold boopParams={{ scale: 1.2, timing: 200 }}>
                <img
                  src="/assets/images/about-2024.png"
                  alt=""
                  class="animate__animated animate__fadeInUp animate__delay-1s animate__slow"
                  style="border-radius: 20px;"
                />
              </BoopActionHold>
            </div>
          </div>

          <div class="discover-items pb-0 pt-1 ">
            <div class="container">
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-xs-12 col-lg-12">
                  <MediaImages
                    imageFromAndTo={[41, 50]}
                    autoplayDuration={3000}
                  />
                </div>
                <div class="col-sm-12 col-xs-12 col-lg-12">
                  <MediaImages
                    imageFromAndTo={[11, 20]}
                    autoplayDuration={7000}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="categories-collections">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec" />
              <h2><em>Testimoni</em> Halal Expo Indonesia 2023.</h2>
            </div>
          </div>
          <div class="col-lg-12 text-white text-justify ">
            Halal Expo Indonesia 2023 left a lasting impression as a
            comprehensive and impactful event within the halal industry.
            Attendees were provided with valuable insights into emerging trends
            and market opportunities, fostering fruitful networking and
            collaboration among industry stakeholders. The event's organization
            and execution were commendable, demonstrating a commitment to
            excellence in promoting halal entrepreneurship and innovation.
          </div>
          <div class="row gx-2 mt-4 justify-content-md-center">
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                {#each testimonies as _, i}
                  <li
                    data-target="#myCarousel"
                    data-slide-to={i}
                    class:active={i === 0}
                  />
                {/each}
              </ol>
              <div class="carousel-inner">
                {#each testimonies as testimony, i}
                  <div
                    class={"p-4 carousel-item " +
                      (i === 0 ? "active" : "") +
                      " " +
                      (i % 2 === 0
                        ? "border-warning bg-warning"
                        : "border-secondary bg-secondary")}
                  >
                    <div class="img-box">
                      <i class="fas fa-comments fa-3x" />
                    </div>
                    <div
                      class="border-white bg-white py-3"
                      style=" border-radius: 8px; border: 2px solid red;"
                    >
                      <p
                        style="font-size:20px;"
                        class="text-dark testimonial px-4"
                      >
                        {testimony.text}
                      </p>
                    </div>
                    <p class="text-dark overview pt-4">
                      <b>{testimony.author}</b>
                    </p>
                  </div>
                {/each}
              </div>
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left" />
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="item-details-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <div class="line-dec" />
              <h2 class="text-dark"><em>The</em> Venue</h2>
            </div>
          </div>
          <div class="col-lg-12 mb-4">
            <strong>
              Exhibitor Area Indonesia Convention Exhibition (ICE) BSD,
              Tangerang Hall 9
            </strong>
          </div>
          <div class="col-lg-12 row">
            <div class="col-md-6">
              <div class="right-image">
                <FlipImage
                  images={[
                    "/assets/images/about-map-0.png",
                    "/assets/images/about-map-1.jpg",
                    "/assets/images/about-map-2.jpg",
                  ]}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div style="width: 100%">
                <iframe
                  width="100%"
                  height="420"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Indonesia+Convention+Exhibition+(ICE)+BSD+City&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12 align-self-center">
            <strong>
              The Indonesia Convention Exhibition (ICE) is a leading convention
              and exhibition center in Indonesia with 220.000 square meters of
              gross space. Located in Bumi Serpong Damai (BSD) City, Tangerang
              Selatan, ICE BSD offers modern facilities for hosting various
              large-scale events, including convention, exhibitions, concerts,
              and other special events.
              <br /><br />
            </strong>
            <p class="text-dark">
              Address : Jl. BSD Grand Boulevard Raya No.1, BSD City, Tangerang,
              15339 <strong
                >(link to <a
                  target="_blank noreferrer"
                  href="https://ul.waze.com/ul?place=ChIJBSMVX1P7aS4RePSYsNhuQDQ&ll=-6.30042020%2C106.63645170&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                  >Waze</a
                >
                and
                <a
                  target="_blank noreferrer"
                  href="https://maps.app.goo.gl/wLwMZBSccKmktTMk9">Google Map</a
                >)</strong
              >
            </p>
          </div>

          <div class="col-lg-12 mb-4 mt-4">
            <strong> How to reach ICE BSD? </strong>
          </div>
          <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12 mt-4">
            <div class="col-md-12 text-center">
              <i class="fa fa-car text-warning fa-4x" />
            </div>
            <div class="col-md-12 text-center">
              <h6 class="text-dark text-center mb-2 mt-4">By Car</h6>
              <p class="text-dark">
                Using private car to go right away to the location.
              </p>
            </div>
            <div
              class="col-lg-12 row"
              style="display: flex; justify-content: space-between;"
            >
              <div class="col-lg-6 text-center">
                <a
                  class="mx-auto"
                  href="https://maps.app.goo.gl/L9YeGGtwvRzvL3Hp7"
                  style="display: flex; align-items: center;justify-content: center;"
                  ><img
                    src="/assets/images/icon-google-maps.png"
                    style="height: 48px; width:auto"
                    class="mr-1"
                  /> Open in Google Maps</a
                >
              </div>
              <div class="col-lg-6  text-center">
                <a
                  class="mx-auto"
                  href="https://ul.waze.com/ul?place=ChIJBSMVX1P7aS4RePSYsNhuQDQ&ll=-6.30042020%2C106.63645170&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                  style="display: flex; align-items: center;justify-content: center;"
                >
                  <img
                    src="/assets/images/icon-waze.png"
                    class="mr-1 d-inline"
                    style="height: 48px; width:auto"
                  /> Open in Waze</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12 mt-4">
            <div class="col-md-12 text-center">
              <i class="fa fa-bus text-info fa-4x" />
            </div>
            <div class="col-md-12 text-center">
              <h6 class="text-dark text-center  mb-2 mt-4">
                By Public Transportation
              </h6>
              <p class="text-dark">
                Using train then stop at Cisauk Station or Rawabuntu Station
              </p>
            </div>
            <div
              class="col-lg-12 row"
              style="display: flex; justify-content: space-between;"
            >
              <div class="col-lg-6 text-center">
                <a
                  class="mx-auto"
                  href="https://www.google.com/maps/dir//Stasiun+Cisauk,+Jl.+Raya+Cisauk+Lapan,+Sampora,+Kec.+Cisauk,+Kabupaten+Tangerang,+Banten/@-6.3244764,106.6367229,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69e4bd4cde0881:0xfaa1768e23c3b55c!2m2!1d106.6415885!2d-6.3244818!3e0?entry=ttu"
                  style="display: flex; align-items: center;justify-content: center;"
                  ><img
                    src="/assets/images/icon-google-maps.png"
                    style="height: 48px; width:auto"
                    class="mr-1"
                  /> See Cisauk Station</a
                >
              </div>
              <div class="col-lg-6 text-center">
                <a
                  class="mx-auto"
                  href="https://www.google.com/maps/dir//Stasiun+Rawa+Buntu,+Cilenggang,+South+Tangerang+City,+Banten/@-6.3147376,106.6347367,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69e527a8a8cc65:0xd173a2597dd923e1!2m2!1d106.6760222!2d-6.3148245!3e0?entry=ttu"
                  style="display: flex; align-items: center;justify-content: center;"
                  ><img
                    src="/assets/images/icon-google-maps.png"
                    style="height: 48px; width:auto"
                    class="mr-1"
                  /> See Rawabuntu Station</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="item-details-page">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-12 align-self-center mb-5"
            style="justify-content: center;"
          >
            <center>
              <iframe
                allowfullscreen="allowfullscreen"
                scrolling="no"
                class="fp-iframe"
                src="https://heyzine.com/flip-book/7e6ad3a8aa.html"
                style="border: 1px solid lightgray; width: 100%; height: 400px;"
              />
            </center>

            <center>
              <div class="main-button">
                <a href="https://drive.google.com/file/d/1if_n0jsSw0bOWRgZtKEGhEDrv8-Q4FrC/view?usp=sharing">Download JHEC Brochure</a>
              </div>
            </center>
          </div>
          <div class="col-lg-7 p-4">
            <img
              src="/assets/images/logo-lima-umat.png"
              alt=""
              style="border-radius: 20px;height:auto;width:53%"
            />
          </div>

          <div class="col-lg-5 align-self-center mt-4">
            <h4 class="text-dark">Organizer</h4>
            <p class="text-dark">
              PT. Lima Event Indonesia, a leading event management company
              specializing in the halal industry. With years of experience and
              expertise, we are committed to delivering a successful and
              impactful event that brings value to our attendees, exhibitors,
              and partners.
            </p>
          </div>

          <div class="col-lg-7 p-4 mt-4">
            <img
              src="/assets/images/icon-kpmi.png"
              alt=""
              style="border-radius: 20px;height:auto;width:50%"
            />
          </div>

          <div class="col-lg-5 align-self-center">
            <h4 class="text-dark">Hosted By</h4>
            <p class="text-dark">
              KPMI (Komunitas Pengusaha Muslim Indonesia) is a non profit
              organization founded in 2010 in Bogor, Indonesia. It serves as a
              collective platform for its members to enhance their comprehension
              of Islamic faith and principles in conducting business
              transactions (muamalah)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</MainLayout>

<style>
  .carousel .carousel-item {
    text-align: center;
  }

  .carousel .img-box {
    width: 75px;
    height: 75px;
    margin: 0 auto 10px;
  }

  .carousel .testimonial {
    font-style: italic;
    color: #999;
    margin-bottom: 10px;
  }

  .carousel .overview {
    font-size: 14px;
    text-transform: uppercase;
    color: #1abc9c;
  }

  .carousel .carousel-indicators {
    bottom: -70px;
  }

  .carousel-indicators li,
  .carousel-indicators li.active {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 1px 2px;
    box-sizing: border-box;
  }

  .carousel-indicators li {
    background: #e2e2e2;
    border: 4px solid #fff;
  }

  .carousel-indicators li.active {
    background: #1abc9c;
    border: 5px double #fff;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 30px;
    height: 30px;
    background: darkblue;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel-control-prev i,
  .carousel-control-next i {
    font-size: 20px;
    color: #fff;
  }
</style>
